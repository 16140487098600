import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";

import axios from "axios";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Textfield from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Check from "@material-ui/icons/Check";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  image: {
    position: "relative",
    height: 160,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 60,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    maxHeight: "100%",
    maxWidth: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  colorDot: {
    padding: "5px",
    marginLeft: "calc(50% - 15px)",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    color: "white",
  },
  colorPicker: {
    width: "80%",
    height: "40px",
  },
}));

export default function Setup(props) {
  const classes = useStyles();
  const [values, setValues] = useState({
    use_all_questions: props.setupData.use_all_questions,
    questions_length: props.setupData.questions_length,
    admin_email_1: props.setupData.admin_email_1,
    admin_email_2: props.setupData.admin_email_2,
    domain_whitelist: props.setupData.domain_whitelist,
    email_subject: props.setupData.email_subject,
    answer_time: props.setupData.answer_time,
    // single
    single_admin: props.setupData.single_admin,
    single_validation: props.setupData.single_validation,
    single_quizlink: props.setupData.single_quizlink,
    single_result: props.setupData.single_result,
    // office
    office_admin: props.setupData.office_admin,
    office_validation: props.setupData.office_validation,
    office_quizlink: props.setupData.office_quizlink,
    admin_results: props.setupData.admin_results,
    office_results: props.setupData.office_results,
    // html
    html_template_success: props.setupData.html_template_success,
    html_template_error: props.setupData.html_template_error,
  });
  // const [sw, setSw] = useState(JSON.parse(props.setupData.software));
  // const [swv, setSwv] = useState(JSON.parse(props.setupData.software_versions));
  // const [m, setM] = useState(JSON.parse(props.setupData.moduls));
  const [c, setC] = useState(JSON.parse(props.setupData.categories));
  const [questionsLength, setQuestionsLength] = useState(0);

  // const [templates, setTemplates] = useState(props.emailTemplate);
  const templates = props.emailTemplate;
  const sw = JSON.parse(props.setupData.software);
  const swv = JSON.parse(props.setupData.software_versions);
  const m = JSON.parse(props.setupData.moduls);
  const cIds = c.map((x) => x.id);

  // axios
  //   .get(props.API_PATH + "questionsByCategory/" + cIds)
  //   .then((response) => {
  //     console.log(response);
  //   })
  //   .catch((err) => {
  //     let error = err.toString();
  //     console.log(error);
  //   });

  axios
    .get(props.API_PATH + "questionsLengthByCategory/" + cIds)
    .then((response) => {
      setQuestionsLength(response.data);
    })
    .catch((err) => {
      let error = err.toString();
      console.log(error);
    });

  const handleChange = (prop, value) => {
    if (prop === "use_all_questions") {
      setValues({ ...values, [prop]: value ? 1 : 0 });
    } else if (
      prop === "software" ||
      prop === "software_versions" ||
      prop === "moduls" ||
      prop === "categories"
    ) {
      //     setChecked({ ...checked, [prop]: !checked[prop] })
      setValues({ ...values, [prop]: value });
    } else {
      setValues({ ...values, [prop]: value });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let setupData = new FormData();

    setupData.append("use_all_questions", values.use_all_questions);
    setupData.append("questions_length", values.questions_length);
    setupData.append("admin_email_1", values.admin_email_1);
    setupData.append("admin_email_2", values.admin_email_2);
    setupData.append("domain_whitelist", values.domain_whitelist);
    setupData.append("email_subject", values.email_subject);
    // single
    setupData.append("single_admin", values.single_admin);
    setupData.append("single_validation", values.single_validation);
    setupData.append("single_quizlink", values.single_quizlink);
    setupData.append("single_result", values.single_result);
    // office
    setupData.append("office_admin", values.office_admin);
    setupData.append("office_validation", values.office_validation);
    setupData.append("office_quizlink", values.office_quizlink);
    setupData.append("admin_results", values.admin_results);
    setupData.append("office_results", values.office_results);
    // html
    setupData.append("html_template_success", values.html_template_success);
    setupData.append("html_template_error", values.html_template_error);
    setupData.append("answer_time", values.answer_time);
    setupData.append("software", JSON.stringify(sw));
    setupData.append("software_versions", JSON.stringify(swv));
    setupData.append("moduls", JSON.stringify(m));
    setupData.append("categories", JSON.stringify(c));

    axios
      .post(props.API_PATH + "create/setup", setupData)
      .then(() => {
        //setValues({});
        props.setOpenSnackbar(true);
        props.setFetchAll(true);
        props.setSnackbarSeverity("success");
        props.setSnackbarMsg("Setup erstellt");
      })
      .catch((err) => {
        let error = err.toString();
        console.log(error);
        props.setSnackbarMsg("Fehler");
        props.setOpenSnackbar(true);
        props.setSnackbarSeverity("error");
      });
  };

  console.log(props);

  return (
    <Grid container justify="center" spacing={3}>
      {props.isAdmin === 2 ? (
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h5" gutterBottom>
              Inhalte und Grundeinstellungen
            </Typography>
            <div>
              <form className={""} noValidate autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Wählen Sie die zu evaluierenden Kategorien
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      value={c}
                      onChange={(event, value) => setC([...value])}
                      fullWidth
                      multiple
                      id="checkboxes-tags-demo"
                      options={props.categories}
                      disableCloseOnSelect
                      getOptionLabel={(category) => category.name}
                      renderInput={(params) => (
                        <Textfield
                          {...params}
                          variant="outlined"
                          label="Kategorien wählen"
                          placeholder="Kategorien"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item align="center" xs={4}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Checkbox
                          name="useAllQuestions"
                          checked={values.use_all_questions && true}
                          onChange={(event) =>
                            handleChange(
                              "use_all_questions",
                              event.target.checked
                            )
                          }
                        />
                      }
                      label="Alle Fragen zu den gewählten Kategorien evaluieren?"
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Textfield
                      disabled={values.use_all_questions === 1 ? true : false}
                      id="questionSetLength"
                      value={
                        values.use_all_questions === 1
                          ? questionsLength
                          : values.questions_length
                      }
                      onChange={(event) =>
                        handleChange("questions_length", event.target.value)
                      }
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          pattern: "0-9",
                          max: questionsLength,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      label="Anzahl der Fragen"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Textfield
                      id="answer_time"
                      value={values.answer_time}
                      onChange={(event) =>
                        handleChange("answer_time", event.target.value)
                      }
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, pattern: "0-9" },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      label="Antwortzeit"
                      helperText="in Sekunden pro Frage"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Textfield
                      id="admin_email_1"
                      value={values.admin_email_1}
                      onChange={(event) =>
                        handleChange("admin_email_1", event.target.value)
                      }
                      type="email"
                      variant="outlined"
                      fullWidth
                      required
                      label="Admin E-Mail 1"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Textfield
                      id="admin_email_2"
                      value={values.admin_email_2}
                      onChange={(event) =>
                        handleChange("admin_email_2", event.target.value)
                      }
                      type="email"
                      variant="outlined"
                      fullWidth
                      label="Admin E-Mail 2"
                      helperText="optional"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      id="domain_whitelist"
                      value={values.domain_whitelist}
                      onChange={(event) =>
                        handleChange("domain_whitelist", event.target.value)
                      }
                      type="email"
                      variant="outlined"
                      fullWidth
                      label="Domains ohne Freischaltung / Whitelist"
                      helperText="Mit Komma separieren"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">E-Mail Templates</Typography>
                    <Typography variant="subtitle1">
                      Weisen Sie Ihre erstellten E-Mail Templates den
                      Einstellungen zu.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      id="email_subject"
                      value={values.email_subject}
                      onChange={(event) =>
                        handleChange("email_subject", event.target.value)
                      }
                      type="text"
                      variant="outlined"
                      fullWidth
                      label="E-Mail Betreff"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Einzelanmeldung
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="select-outlined-label">
                                Admin Info
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="select-outlined-label"
                                id="select-outlined"
                                value={values.single_admin}
                                onChange={(event) =>
                                  handleChange(
                                    "single_admin",
                                    event.target.value
                                  )
                                }
                                label="Admin Infos"
                              >
                                {templates.map((template) => {
                                  return (
                                    <MenuItem
                                      value={template.id}
                                      key={"key" + template.id}
                                    >
                                      {template.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="select-outlined-label">
                                Benutzervalidierung
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="select-outlined-label"
                                id="select-outlined"
                                value={values.single_validation}
                                onChange={(event) =>
                                  handleChange(
                                    "single_validation",
                                    event.target.value
                                  )
                                }
                                label="Benutzer Validierung"
                              >
                                {templates.map((template) => {
                                  return (
                                    <MenuItem
                                      value={template.id}
                                      key={"key" + template.id}
                                    >
                                      {template.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="select-outlined-label">
                                Benutzer Link zum Quiz
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="select-outlined-label"
                                id="select-outlined"
                                value={values.single_quizlink}
                                onChange={(event) =>
                                  handleChange(
                                    "single_quizlink",
                                    event.target.value
                                  )
                                }
                                label="Benutzer Link zum Quiz"
                              >
                                {templates.map((template) => {
                                  return (
                                    <MenuItem
                                      value={template.id}
                                      key={"key" + template.id}
                                    >
                                      {template.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="select-outlined-label">
                                Benutzer Ergbenis
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="select-outlined-label"
                                id="select-outlined"
                                value={values.single_result}
                                onChange={(event) =>
                                  handleChange(
                                    "single_result",
                                    event.target.value
                                  )
                                }
                                label="Benutzer Ergbenis"
                              >
                                {templates.map((template) => {
                                  return (
                                    <MenuItem
                                      value={template.id}
                                      key={"key" + template.id}
                                    >
                                      {template.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Gruppenanmeldung
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="select-outlined-label">
                                Admin Info
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="select-outlined-label"
                                id="select-outlined"
                                value={values.office_admin}
                                onChange={(event) =>
                                  handleChange(
                                    "office_admin",
                                    event.target.value
                                  )
                                }
                                label="Admin Infos"
                              >
                                {templates.map((template) => {
                                  return (
                                    <MenuItem
                                      value={template.id}
                                      key={"key" + template.id}
                                    >
                                      {template.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="select-outlined-label">
                                Gruppenvalidierung
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="select-outlined-label"
                                id="select-outlined"
                                value={values.office_validation}
                                onChange={(event) =>
                                  handleChange(
                                    "office_validation",
                                    event.target.value
                                  )
                                }
                                label="Gruppenvalidierung"
                              >
                                {templates.map((template) => {
                                  return (
                                    <MenuItem
                                      value={template.id}
                                      key={"key" + template.id}
                                    >
                                      {template.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="select-outlined-label">
                                Benutzer Link zum Quiz
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="select-outlined-label"
                                id="select-outlined"
                                value={values.office_quizlink}
                                onChange={(event) =>
                                  handleChange(
                                    "office_quizlink",
                                    event.target.value
                                  )
                                }
                                label="Benutzer Link zum Quiz"
                              >
                                {templates.map((template) => {
                                  return (
                                    <MenuItem
                                      value={template.id}
                                      key={"key" + template.id}
                                    >
                                      {template.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="select-outlined-label">
                                Admin Ergebnis Freigabe
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="select-outlined-label"
                                id="select-outlined"
                                value={values.admin_results}
                                onChange={(event) =>
                                  handleChange(
                                    "admin_results",
                                    event.target.value
                                  )
                                }
                                label="Admin Ergebnis Freigabe"
                              >
                                {templates.map((template) => {
                                  return (
                                    <MenuItem
                                      value={template.id}
                                      key={"key" + template.id}
                                    >
                                      {template.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="select-outlined-label">
                                Büro Ergebnis
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="select-outlined-label"
                                id="select-outlined"
                                value={values.office_results}
                                onChange={(event) =>
                                  handleChange(
                                    "office_results",
                                    event.target.value
                                  )
                                }
                                label="Büro Ergebnisse  "
                              >
                                {templates.map((template) => {
                                  return (
                                    <MenuItem
                                      value={template.id}
                                      key={"key" + template.id}
                                    >
                                      {template.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h6">HTML</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="select-outlined-label">
                                HTML Template Success
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="select-outlined-label"
                                id="select-outlined"
                                value={values.html_template_success}
                                onChange={(event) =>
                                  handleChange(
                                    "html_template_success",
                                    event.target.value
                                  )
                                }
                                label="HTML Template Activation Success"
                              >
                                {templates.map((template) => {
                                  return (
                                    <MenuItem
                                      value={template.id}
                                      key={"key" + template.id}
                                    >
                                      {template.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="select-outlined-label">
                                HTML Template Error
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="select-outlined-label"
                                id="select-outlined"
                                value={values.html_template_error}
                                onChange={(event) =>
                                  handleChange(
                                    "html_template_error",
                                    event.target.value
                                  )
                                }
                                label="HTML Template Activation Error"
                              >
                                {templates.map((template) => {
                                  return (
                                    <MenuItem
                                      value={template.id}
                                      key={"key" + template.id}
                                    >
                                      {template.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      onClick={handleSubmit}
                      variant="outlined"
                      className={""}
                      type="submit"
                      size="large"
                    >
                      <Check></Check>Absenden
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Paper>
        </Grid>
      ) : null}
    </Grid>
  );
}
