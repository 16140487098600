import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import PeopleIcon from "@material-ui/icons/People";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "90%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

export default function Overview(props) {
  const classes = useStyles();

  useEffect(() => {
    props.setFetchAll(true);
  }, []);

  return !props.isLoading ? (
    <Grid container align="center">
      <Grid item xs={4}>
        <Paper
          className={classes.paper}
          style={{ backgroundColor: "#8e4b7c", color: "white" }}
          elevation={3}
        >
          <Typography variant="h5" align="left" gutterBottom>
            Fragen
          </Typography>
          <Typography variant="h4" align="right" gutterBottom>
            {props.questions.length} <QuestionAnswerIcon />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          className={classes.paper}
          style={{ backgroundColor: "#5593a6", color: "white" }}
          elevation={3}
        >
          <Typography variant="h5" align="left" gutterBottom>
            Evaluierungen
          </Typography>
          <Typography variant="h4" align="right" gutterBottom>
            {props.answers.length} <SpeakerNotesIcon />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          className={classes.paper}
          style={{ backgroundColor: "#4b8e78", color: "white" }}
          elevation={3}
        >
          <Typography variant="h5" align="left" gutterBottom>
            Teilnehmer
          </Typography>
          <Typography variant="h4" align="right" gutterBottom>
            {props.customers.length} <PeopleIcon />
          </Typography>
        </Paper>
      </Grid>
      {/* <Grid item xs={2}>
                    <Paper className={classes.paper} style={{ backgroundColor: '#4b5d8e', color: 'white' }} elevation={3} >
                        <Typography variant="subtitle1" align='left' gutterBottom>
                            Module
                        </Typography>
                        <Typography variant="h4" align='right' gutterBottom>
                            {props.moduls.length} <ViewModuleIcon />
                        </Typography>
                    </Paper>
                </Grid >
                <Grid item xs={2}>
                    <Paper className={classes.paper} style={{ backgroundColor: '#7b6851', color: 'white' }} elevation={3} >
                        <Typography variant="subtitle1" align='left' gutterBottom>
                            Kategorien
                        </Typography>
                        <Typography variant="h4" align='right' gutterBottom>
                            {props.categories.length} <CategoryIcon />
                        </Typography>
                    </Paper>
                </Grid >
                <Grid item xs={2}>
                    <Paper className={classes.paper} style={{ backgroundColor: '#8e4b4b', color: 'white' }} elevation={3} >
                        <Typography variant="subtitle1" align='left' gutterBottom>
                            Software / Versionen
                        </Typography>
                        <Typography variant="h4" align='right' gutterBottom>
                            {props.software.length} / {props.softwareVersions.length}  <SchoolIcon />
                        </Typography>
                    </Paper>
                </Grid > */}
    </Grid>
  ) : (
    <CircularProgress />
  );
}
