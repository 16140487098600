import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ImageIcon from "@material-ui/icons/Image";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DropzoneAreaBase } from "material-ui-dropzone";
import React, { useEffect } from "react";

const theme = createMuiTheme({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 100,
      },
    },
    MuiDropzonePreviewList: {
      root: {
        height: 50,
      },
      imageContainer: {
        top: -80,
        minHeight: 100,
      },
      image: {
        padding: 8,
        height: "100%",
      },
      removeButton: {
        top: "50%",
      },
    },
  },
});

export default function QuestionForm(props) {
  useEffect(() => {
    props.setIsValid(false);
  }, []);
  return (
    <Container>
      <Paper elevation={3} style={{ padding: 40 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Voreinstellungen</Typography>
          </Grid>
          {/* <Grid item xs={6}>
                        <Autocomplete
                            id="size-small-filled-multi"
                            value={props.questionObj.software_id}
                            onChange={
                                (event, newValue) => {
                                    props.setQuestionObj({ ...props.questionObj, software_id: newValue })
                                }}
                            options={props.software}
                            getOptionLabel={(option) => option.name}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={option.name}
                                        size="small"
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} error={!props.questionObj.software_id && !props.isValid ? true : false} variant="outlined" label="Software wählen" placeholder="Software wählen" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            id="size-small-filled-multi"
                            value={props.questionObj.software_version_id}
                            onChange={
                                (event, newValue) => {
                                    props.setQuestionObj({ ...props.questionObj, software_version_id: newValue })
                                }}
                            options={props.softwareVersions}
                            getOptionLabel={(option) => option.name}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={option.name}
                                        size="small"
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} error={!props.questionObj.software_version_id && !props.isValid ? true : false} variant="outlined" label="Softwareversion wählen" placeholder="Softwareversion wählen" />
                            )}
                        />
                    </Grid> */}
          <Grid item xs={6}>
            <Select
              fullWidth
              variant="outlined"
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              //   open={open}
              //   onClose={handleClose}
              //   onOpen={handleOpen}
              value={props.questionObj.category_id}
              onChange={(event) => {
                props.setQuestionObj({
                  ...props.questionObj,
                  category_id: event.target.value,
                });
              }}
            >
              {props.categories.map((category) => {
                return <MenuItem value={category.id}>{category.name}</MenuItem>;
              })}
            </Select>
            {/* <Autocomplete
              id="size-small-filled-multi"
              value={props.questionObj.category_id}
              onChange={(event, newValue) => {
                console.log(newValue);
                props.setQuestionObj({
                  ...props.questionObj,
                  category_id: newValue.id,
                });
              }}
              options={props.categories}
              getOptionLabel={(option) => option.name}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.name}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!props.questionObj.category_id ? true : false}
                  variant="outlined"
                  label="Kategorie wählen"
                  placeholder="Kategorie wählen"
                />
              )}
            /> */}
          </Grid>
          {/* <Grid item xs={6}>
                        <Autocomplete
                            id="size-small-filled-multi"
                            value={props.questionObj.modul_id}
                            onChange={
                                (event, newValue) => {
                                    props.setQuestionObj({ ...props.questionObj, modul_id: newValue })
                                }}
                            options={props.moduls}
                            getOptionLabel={(option) => option.name}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={option.name}
                                        size="small"
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} error={!props.questionObj.modul_id && !props.isValid ? true : false} variant="outlined" label="Modul wählen" placeholder="Modul wählen" />
                            )}
                        />
                    </Grid> */}

          <Grid item xs={6}>
            <TextField
              required
              id="author"
              name="author"
              label="Autor"
              variant="outlined"
              error={
                !props.questionObj.author > 0 && !props.isValid ? true : false
              }
              multiline={true}
              fullWidth
              value={props.questionObj.author}
              onChange={(event, newValue) => {
                props.setQuestionObj({
                  ...props.questionObj,
                  author: event.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Frage und Antworten</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="left">
              Sie können Buchstaben, Wörter und ganze Textpassagen Fett, Kursiv
              Unterstrichen und Durchgestrichen darstellen.<br></br>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="left">
              Und so geht's:
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" align="left">
              <b>FETT</b>
              <br></br>
              <i>KURSIV</i>
              <br></br>
              <u>UNTERSTRICHEN</u>
              <br></br>
              <s>DURCHGESTRICHEN</s>
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1" align="left">
              {"{b ...Fetter Text... b}"} = <b>...Fetter Text...</b>
              <br></br>
              {"{i ...Kursiver Text... i}"} = <i>...Kursiver TEXT...</i>
              <br></br>
              {"{u ...Unterstrichen... u}"} = <u>...Unterstrichen...</u>
              <br></br>
              {"{s ...Durchgestrichen... s}"} = <s>...Durchgestrichen...</s>
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <TextField
              required
              rows={3}
              id="question"
              name="question"
              label="Frage"
              variant="outlined"
              error={
                !props.questionObj.question > 0 && !props.isValid ? true : false
              }
              multiline={true}
              fullWidth
              value={props.questionObj.question}
              onChange={(event, newValue) => {
                props.setQuestionObj({
                  ...props.questionObj,
                  question: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={1}>
            {props.questionObj.image === "NULL" ||
            !props.questionObj.image ? null : (
              <img
                style={{ width: "100%" }}
                src={
                  props.questionObj.imageFile
                    ? props.questionObj.imageFile[0].data
                    : props.API_PATH + props.questionObj.image
                }
              />
            )}
          </Grid>

          <Grid item xs={3}>
            {" "}
            <MuiThemeProvider theme={theme}>
              <DropzoneAreaBase
                Icon={ImageIcon}
                acceptedFiles={["image/*"]}
                filesLimit={1}
                dropzoneText={""}
                fileObjects={
                  new File(
                    [props.API_PATH + props.questionObj.image],
                    props.questionObj.image,
                    {
                      type: "image/png",
                    }
                  )
                }
                onAdd={(files) => {
                  props.setQuestionObj({
                    ...props.questionObj,
                    image: files[0].file.name,
                    imageFile: files,
                  });
                }}
                onDelete={() =>
                  props.setQuestionObj({
                    ...props.questionObj,
                    image: "",
                    imageFile: null,
                  })
                }
              />
            </MuiThemeProvider>
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              id="answer_1"
              name="answer_1"
              variant="outlined"
              label="Richtige Antwort"
              error={
                !props.questionObj.answer_good > 0 && !props.isValid
                  ? true
                  : false
              }
              multiline={true}
              rows={4}
              fullWidth
              value={props.questionObj.answer_good}
              onChange={(event, newValue) => {
                props.setQuestionObj({
                  ...props.questionObj,
                  answer_good: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              id="answer_2"
              name="answer_2"
              variant="outlined"
              label="Falsche Antwort 1"
              error={
                !props.questionObj.answer_bad_1 > 0 && !props.isValid
                  ? true
                  : false
              }
              multiline={true}
              rows={4}
              fullWidth
              value={props.questionObj.answer_bad_1}
              onChange={(event, newValue) => {
                props.setQuestionObj({
                  ...props.questionObj,
                  answer_bad_1: event.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              required
              id="answer_3"
              name="answer_3"
              variant="outlined"
              label="Falsche Antwort 2"
              error={
                !props.questionObj.answer_bad_2 > 0 && !props.isValid
                  ? true
                  : false
              }
              multiline={true}
              rows={4}
              fullWidth
              value={props.questionObj.answer_bad_2}
              onChange={(event, newValue) => {
                props.setQuestionObj({
                  ...props.questionObj,
                  answer_bad_2: event.target.value,
                });
              }}
            />
          </Grid>

          {/* <Grid item xs={12}>
                    <TextField
                        id="answer_4"
                        name="answer_4"
                        label="Falsche Antwort 3"
                        multiline={true}
                        fullWidth
                        value={props.answer4}
                        onChange={event => { props.onChangeA4(event.target.value) }}
                    />
                </Grid> */}
        </Grid>
      </Paper>
    </Container>
  );
}
