import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  image: {
    maxWidth: "100%",
    maxHeight: "500px",
  },

  buttonAnswer: {
    minHeight: "180px",
    backgroundColor: "#303d6b",
    boxShadow: " 6px 8px 3px #00000055",
    "&:hover": {
      backgroundColor: "#ff6105",
      boxShadow: " 6px 8px 3px #00000055",
    },
    "&:disabled": {
      boxShadow: "0 0 0 #00000055",
      color: "white",
    },
    "&:active": {
      backgroundColor: "#ff6105",
      boxShadow: " 4px 5px 1px #00000055",
    },
  },
});

export default function Preview(props) {
  const classes = useStyles();
  let software = null;
  let softwareVersion = null;
  let modul = null;
  let category = null;
  if (
    // props.software &&
    // props.softwareVersions &&
    // props.moduls &&
    props.categories
  ) {
    // software = props.software.find(
    //   (sw) => sw.id === props.questionObj.software_id
    // );
    // softwareVersion = props.softwareVersions.find(
    //   (swv) => swv.id === props.questionObj.software_version_id
    // );
    // modul = props.moduls.find((m) => m.id === props.questionObj.modul_id);
    category = props.categories.find(
      (c) => c.id === props.questionObj.category_id
    );
  } else {
    // software = props.questionObj.software_id;
    // softwareVersion = props.questionObj.software_version_id;
    // modul = props.questionObj.modul_id;
    category = props.questionObj.category_id;
  }

  return (
    <Container>
      <Paper elevation={3} style={{ padding: 40 }}>
        <Grid container justify="center" alignItems="center" spacing={5}>
          <Grid item md={5} xs={12} align="left">
            <Typography variant="h5" align="left">
              Einstellungen zur Frage
            </Typography>
          </Grid>
          <Grid item md={5} xs={12} align="left">
            {/* <Typography variant="subtitle1" align="left">
              Software: {software ? software.name : null}
            </Typography>
            <Typography variant="subtitle1" align="left">
              Software Version: {softwareVersion ? softwareVersion.name : null}
            </Typography>
            <Typography variant="subtitle1" align="left">
              Modul: {modul ? modul.name : null}
            </Typography> */}
            <Typography variant="subtitle1" align="left">
              Kategorie: {category ? category.name : null}
            </Typography>
            <Typography variant="subtitle1" align="left">
              Author: {props.questionObj.author}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={3} style={{ marginTop: 40, marginBottom: 40 }}>
        <Grid container justify="center" alignItems="center" spacing={5}>
          <Grid item md={10} xs={12} align="left">
            <Typography variant="h5" align="left">
              Frage und Antworten
            </Typography>
          </Grid>
          <Grid item md={7} xs={6} align="left">
            <p
              style={{ fontSize: "1.5rem" }}
              dangerouslySetInnerHTML={{
                __html: props.makeBold(props.questionObj.question),
              }}
            >
              {/* {props.makeBold(props.questionObj.question)} */}
            </p>
          </Grid>
          <Grid item md={4} xs={6} align="center">
            {props.questionObj.image && props.questionObj.image !== "NULL" ? (
              <img
                className={classes.image}
                src={
                  props.questionObj.imageFile
                    ? props.questionObj.imageFile[0].data
                    : props.API_PATH + props.questionObj.image
                }
              />
            ) : null}
          </Grid>
          <Grid item md={10} xs={12} align="left">
            <Grid container justify="center" alignItems="center" spacing={3}>
              <Grid item md={4} xs={12}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  className={classes.buttonAnswer}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: props.makeBold(props.questionObj.answer_good),
                    }}
                  ></p>
                </Button>
              </Grid>
              <Grid item md={4} xs={12}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  className={classes.buttonAnswer}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: props.makeBold(props.questionObj.answer_bad_1),
                    }}
                  ></p>
                  {/* <Typography>
                    {props.makeBold(props.questionObj.answer_bad_1)}
                  </Typography> */}
                </Button>
              </Grid>
              <Grid item md={4} xs={12}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  className={classes.buttonAnswer}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: props.makeBold(props.questionObj.answer_bad_2),
                    }}
                  ></p>
                  {/* <Typography>
                    {props.makeBold(props.questionObj.answer_bad_2)}
                  </Typography> */}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
