import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import axios from "axios";
import moment from "moment";

// MUI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// PAGES
import Overview from "./Overview";
import Questions from "./Questions";
import Email from "./EmailEditor";
import Software from "./Software";
import TableUser from "./TableUser";
import Setup from "./Setup";
import Checkout from "./Checkout";
import Import from "./Import";
import SignUp from "./SignUp";

// LOGO
import logo from "../Blau-Archiquiz.svg";

// MUI ICONS
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AppsIcon from "@material-ui/icons/Apps";
import SettingsIcon from "@material-ui/icons/Settings";
// import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import CreateIcon from "@material-ui/icons/Create";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

// AUTH
import {
  verifyTokenAsync,
  userLogoutAsync,
} from "./../asyncActions/authAsyncActions";
import { userLogout, verifyTokenEnd } from "./../actions/authActions";

import { setAuthToken } from "./../services/auth";
import { getUserListService } from "./../services/user";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as Linker,
} from "react-router-dom";

const API_PATH = process.env.REACT_APP_API_URL;

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#303d6b",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 3,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: "rgba(255,255,255,0.0)",
  },
  fixedHeight: {
    height: 240,
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const authObj = useSelector((state) => state.auth);

  const { user, token, expiredAt } = authObj;
  const [userList, setUserList] = useState([]);

  const [data_customer, setDataCustomer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [moduls, setModuls] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [software, setSoftware] = useState([]);
  const [softwareVersions, setSoftwareVersions] = useState([]);
  const [setupData, setSetupData] = useState({});
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [template, setTemplate] = useState({});

  const [customersAnswers, setCustomersAnswers] = useState([]);

  const [fetchAll, setFetchAll] = useState(true);

  const [triggerUsers, setTriggerUsers] = useState(true);

  // questions search
  const [q, setQ] = useState("");

  // SNACKBAR
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  // handle click event of the logout button
  const handleLogout = () => {
    dispatch(userLogoutAsync());
  };

  // get user list
  const getUserList = async () => {
    const result = await getUserListService();
    if (result.error) {
      dispatch(verifyTokenEnd());
      if (result.response && [401, 403].includes(result.response.status))
        dispatch(userLogout());
      return;
    }
    setUserList(result.data);
  };

  // set timer to renew token
  useEffect(() => {
    setAuthToken(token);
    const verifyTokenTimer = setTimeout(() => {
      dispatch(verifyTokenAsync(true));
    }, moment(expiredAt).diff() - 10 * 1000);
    return () => {
      clearTimeout(verifyTokenTimer);
    };
  }, [expiredAt, token]);

  // get user list on page load
  useEffect(() => {
    let custmAnswArray = [];

    customers.forEach((customer) => {
      if (answers) {
        answers.forEach((answer) => {
          if (customer.customer_id === answer.customer_id) {
            customer.points = answer.points;
            // customer.points = JSON.parse(customer.points)
            customer.questions_length = answer.questions_length;
            // customer.percent = (100 * answer.points) / answer.questions_length
            customer.duration = answer.duration;
          }
        });
        custmAnswArray.push(customer);
      }
    });
    setCustomersAnswers(custmAnswArray);
  }, [answers, customers]);

  const [users, setUsers] = useState([]);
  const [done, setDone] = useState(false);

  // React.useEffect(() => {
  //   if (customersAnswers) {
  //     let usersArr = []
  //     let userObj = {}
  //     customersAnswers.forEach(user => {
  //       if (user.points) {
  //         userObj = user
  //         userObj.points = JSON.parse(userObj.points)
  //         console.log(userObj);
  //         usersArr.push(userObj)
  //       }
  //     });
  //     setUsers(usersArr)
  //     setDone(true)
  //   }
  // }, [customersAnswers])

  // get user list on page load
  // useEffect(() => {
  //   getUserList();
  // }, []);

  useEffect(() => {
    if (fetchAll) {
      setIsLoading(true);
      const requestM = axios.get(API_PATH + "getAll/moduls");
      const requestCU = axios.get(API_PATH + "getAll/customers");
      const requestCA = axios.get(API_PATH + "getAll/categories");
      const requestS = axios.get(API_PATH + "getAll/software");
      const requestSV = axios.get(API_PATH + "getAll/software_versions");
      const requestQ = axios.get(API_PATH + "getAll/questions");
      const requestA = axios.get(API_PATH + "getAll/answers");
      const requestSE = axios.get(API_PATH + "getOne/setup/max/" + 1);
      const requestET = axios.get(API_PATH + "getAll/email_template");
      axios
        .all([
          requestM,
          requestCU,
          requestCA,
          requestS,
          requestSV,
          requestQ,
          requestA,
          requestSE,
          requestET,
        ])
        .then(
          axios.spread((...responses) => {
            const responseM = responses[0].data;
            const responseCU = responses[1].data;
            const responseCA = responses[2].data;
            const responseS = responses[3].data;
            const responseSV = responses[4].data;
            const responseQ = responses[5].data;
            const responseA = responses[6].data;
            const responseSE = responses[7].data;
            const responseET = responses[8].data;
            setModuls(responseM);
            responseCU.sort((a, b) => b.id - a.id);
            setCustomers(responseCU);
            setCategories(responseCA);
            setSoftware(responseS);
            setSoftwareVersions(responseSV);
            responseQ.sort((a, b) => b.id - a.id);
            setQuestions(responseQ);
            setAnswers(responseA);
            setSetupData(responseSE[0]);
            if (responseET.length > 0) {
              responseET.sort((a, b) => b.id - a.id);
              setEmailTemplate(responseET);
            } else setEmailTemplate([]);
            setFetchAll(false);
            setIsLoading(false);
          })
        )
        .catch((errors) => {
          // errors.
        });
    }
  }, [fetchAll]);

  const [answersPercent, setAnswersPercent] = React.useState([]);

  React.useEffect(() => {
    if (answers) {
      let count30 = 0;
      let count40 = 0;
      let count50 = 0;
      let count60 = 0;
      let count70 = 0;
      let count80 = 0;
      let count90 = 0;
      let count100 = 0;

      let percentArray = [];
      answers.forEach((answer) => {
        let perc = (100 * answer.points) / answer.questions_length;
        if (perc < 30) {
          count30++;
        }

        if (perc >= 40 && perc < 50) {
          count40++;
        }
        if (perc >= 50 && perc < 60) {
          count50++;
        }
        if (perc >= 60 && perc < 70) {
          count60++;
        }
        if (perc >= 70 && perc < 80) {
          count70++;
        }
        if (perc >= 80 && perc < 90) {
          count80++;
        }
        if (perc >= 90 && perc < 100) {
          count90++;
        }
        if (perc == 100) {
          count100++;
        }
      });

      percentArray = [
        { label: "< 30%", count: count30 },
        { label: "40%", count: count40 },
        { label: "50%", count: count50 },
        { label: "60%", count: count60 },
        { label: "70%", count: count70 },
        { label: "80%", count: count80 },
        { label: "90%", count: count90 },
        { label: "100%", count: count100 },
      ];

      setAnswersPercent(percentArray);
    }
  }, [answers]);

  function userRole(admin) {
    if (admin === 3) {
      return "Viewer";
    }
    if (admin === 2) {
      return "Superadmin";
    }
    if (admin === 1) {
      return "Admin";
    }
    if (admin === 0) {
      return "Manager";
    }
  }

  const makeBold = (input) => {
    if (input) {
      var output = input;

      if (
        input.includes("{b") ||
        input.includes("{i") ||
        input.includes("{u") ||
        input.includes("{s")
      ) {
        output = output.replace(/{b /g, "<b>");
        output = output.replace(/ b}/g, "</b>");
        output = output.replace(/{i /g, "<i>");
        output = output.replace(/ i}/g, "</i>");
        output = output.replace(/{u /g, "<u>");
        output = output.replace(/ u}/g, "</u>");
        output = output.replace(/{s /g, "<s>");
        output = output.replace(/ s}/g, "</s>");
        return output;
      } else return input;
    }
  };

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Evaluierungstest Admincenter
            </Typography>
            <IconButton
              aria-controls="user-menu"
              aria-haspopup="true"
              onClick={handlePopoverOpen}
            >
              <StyledBadge
                align="right"
                overlap="circle"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar>
                  {user.username
                    ? user.username.slice(0, 1).toUpperCase()
                    : null}
                </Avatar>
              </StyledBadge>
            </IconButton>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              elevation={0}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(anchorEl)}
              onClose={handlePopoverClose}
            >
              <MenuItem onClick={handlePopoverClose}>
                <Typography variant="subtitle1">
                  {user.username ? user.username : null}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handlePopoverClose}>
                <Typography variant="subtitle2">{user.email}</Typography>
              </MenuItem>
              <MenuItem onClick={handlePopoverClose}>
                <Typography variant="subtitle2">
                  #{userRole(user.isAdmin)}
                </Typography>
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <div>
              {user.isAdmin === 0 ? null : (
                <Linker
                  style={{ textDecoration: "none", color: "black" }}
                  to="/dashboard"
                >
                  <ListItem button>
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItem>
                </Linker>
              )}
              {user.isAdmin === 3 ? null : (
                <Linker
                  style={{ textDecoration: "none", color: "black" }}
                  to="/newQuestion"
                >
                  <ListItem button>
                    <ListItemIcon>
                      <CreateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Frage erstellen" />
                  </ListItem>
                </Linker>
              )}
              {user.isAdmin === 3 ? null : (
                <Linker
                  style={{ textDecoration: "none", color: "black" }}
                  to="/importQuestions"
                >
                  <ListItem button>
                    <ListItemIcon>
                      <ImportExportIcon />
                    </ListItemIcon>
                    <ListItemText primary="Fragen Importieren" />
                  </ListItem>
                </Linker>
              )}
              <Linker
                style={{ textDecoration: "none", color: "black" }}
                to="/tableQuestions"
              >
                <ListItem button>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Fragen Sammlung" />
                </ListItem>
              </Linker>
              {/* <Linker style={{ textDecoration: 'none', color: 'black' }} to="/software">
                <ListItem button>
                  <ListItemIcon>
                    <AppsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Software etc." />
                </ListItem>
              </Linker> */}
              {user.isAdmin >= 1 && user.isAdmin != 3 ? (
                <Linker
                  style={{ textDecoration: "none", color: "black" }}
                  to="/setup"
                >
                  <ListItem button>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Einstellungen" />
                  </ListItem>
                </Linker>
              ) : null}
              {user.isAdmin >= 1 && user.isAdmin != 3 ? (
                <Linker
                  style={{ textDecoration: "none", color: "black" }}
                  to="/email"
                >
                  <ListItem button>
                    <ListItemIcon>
                      <SupervisedUserCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="E-Mail Templates" />
                  </ListItem>
                </Linker>
              ) : null}
              {user.isAdmin === 2 ? (
                <Linker
                  style={{ textDecoration: "none", color: "black" }}
                  to="/signUp"
                >
                  <ListItem button>
                    <ListItemIcon>
                      <PersonAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logins" />
                  </ListItem>
                </Linker>
              ) : null}
              <ListItem button onClick={handleLogout}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </div>
          </List>
          <Divider />
          <img
            style={{
              position: "absolute",
              padding: 20,
              bottom: 10,
              width: "100%",
            }}
            alt="Graphisoft Nord Logo"
            src={
              "https://graphisoft-nord.de/wp-content/uploads/2021/02/GraphisoftNordGmbH.png"
            }
          />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {/* <Paper className={classes.paper}> */}
                <Switch>
                  <Route path="/setup">
                    {/* <Setup
                      categories={categories}
                      software={software}
                      softwareVersions={softwareVersions}
                      moduls={moduls}
                      customers={customers}
                      setFetchAll={setFetchAll}
                      setupData={setupData}
                      isAdmin={user.isAdmin}
                      addZero={addZero}
                      handleClose={handleClose}
                      setOpenSnackbar={setOpenSnackbar}
                      setSnackbarMsg={setSnackbarMsg}
                      setSnackbarSeverity={setSnackbarSeverity}
                      API_PATH={API_PATH}
                    /> */}
                    <Software
                      emailTemplate={emailTemplate}
                      customers={customers}
                      setupData={setupData}
                      isAdmin={user.isAdmin}
                      addZero={addZero}
                      handleClose={handleClose}
                      setOpenSnackbar={setOpenSnackbar}
                      setSnackbarMsg={setSnackbarMsg}
                      setSnackbarSeverity={setSnackbarSeverity}
                      API_PATH={API_PATH}
                      setFetchAll={setFetchAll}
                      software={software}
                      softwareVersions={softwareVersions}
                      moduls={moduls}
                      categories={categories}
                      questionsLength={questions.length}
                    />
                  </Route>
                  <Route path="/email">
                    <Email
                      template={template}
                      setTemplate={setTemplate}
                      emailTemplate={emailTemplate}
                      categories={categories}
                      software={software}
                      softwareVersions={softwareVersions}
                      moduls={moduls}
                      customers={customers}
                      setFetchAll={setFetchAll}
                      setupData={setupData}
                      addZero={addZero}
                      handleClose={handleClose}
                      setOpenSnackbar={setOpenSnackbar}
                      setSnackbarMsg={setSnackbarMsg}
                      setSnackbarSeverity={setSnackbarSeverity}
                      API_PATH={API_PATH}
                    />
                  </Route>
                  <Route path="/users">
                    <TableUser
                      users={users}
                      done={done}
                      setTriggerUsers={setTriggerUsers}
                      triggerUsers={triggerUsers}
                      isAdmin={user.isAdmin}
                      addZero={addZero}
                      handleClose={handleClose}
                      setOpenSnackbar={setOpenSnackbar}
                      setSnackbarMsg={setSnackbarMsg}
                      setSnackbarSeverity={setSnackbarSeverity}
                      API_PATH={API_PATH}
                      data_users={customers}
                    />
                  </Route>
                  <Route path="/tableQuestions">
                    <Questions
                      setFetchAll={setFetchAll}
                      makeBold={makeBold}
                      isAdmin={user.isAdmin}
                      addZero={addZero}
                      handleClose={handleClose}
                      setOpenSnackbar={setOpenSnackbar}
                      setSnackbarMsg={setSnackbarMsg}
                      setSnackbarSeverity={setSnackbarSeverity}
                      API_PATH={API_PATH}
                      questions={questions}
                      software={software}
                      softwareVersions={softwareVersions}
                      moduls={moduls}
                      categories={categories}
                      setupData={setupData}
                      q={q}
                      setQ={setQ}
                    />
                  </Route>
                  <Route path="/importQuestions">
                    <Import
                      setFetchAll={setFetchAll}
                      makeBold={makeBold}
                      isAdmin={user.isAdmin}
                      addZero={addZero}
                      handleClose={handleClose}
                      setOpenSnackbar={setOpenSnackbar}
                      setSnackbarMsg={setSnackbarMsg}
                      setSnackbarSeverity={setSnackbarSeverity}
                      API_PATH={API_PATH}
                      questions={questions}
                      software={software}
                      softwareVersions={softwareVersions}
                      moduls={moduls}
                      categories={categories}
                      setupData={setupData}
                    />
                  </Route>
                  <Route path="/software">
                    <Software
                      emailTemplate={emailTemplate}
                      isAdmin={user.isAdmin}
                      addZero={addZero}
                      handleClose={handleClose}
                      setOpenSnackbar={setOpenSnackbar}
                      setSnackbarMsg={setSnackbarMsg}
                      setSnackbarSeverity={setSnackbarSeverity}
                      API_PATH={API_PATH}
                      setFetchAll={setFetchAll}
                      software={software}
                      softwareVersions={softwareVersions}
                      moduls={moduls}
                      categories={categories}
                    />
                  </Route>
                  <Route path="/newQuestion">
                    <Checkout
                      setFetchAll={setFetchAll}
                      makeBold={makeBold}
                      software={software}
                      softwareVersions={softwareVersions}
                      moduls={moduls}
                      customers={customers}
                      isAdmin={user.isAdmin}
                      answers={answers}
                      categories={categories}
                      questions={questions}
                      setOpenSnackbar={setOpenSnackbar}
                      setSnackbarMsg={setSnackbarMsg}
                      setSnackbarSeverity={setSnackbarSeverity}
                      API_PATH={API_PATH}
                    />
                  </Route>
                  <Route path="/dashboard">
                    {customers.length > 0 ? (
                      <Overview
                        makeBold={makeBold}
                        answersPercent={answersPercent}
                        isLoading={isLoading}
                        software={software}
                        softwareVersions={softwareVersions}
                        moduls={moduls}
                        customers={customers}
                        isAdmin={user.isAdmin}
                        answers={answers}
                        categories={categories}
                        questions={questions}
                        handleClose={handleClose}
                        setOpenSnackbar={setOpenSnackbar}
                        setSnackbarMsg={setSnackbarMsg}
                        setSnackbarSeverity={setSnackbarSeverity}
                        API_PATH={API_PATH}
                        setFetchAll={setFetchAll}
                      />
                    ) : null}
                    {customers.length > 0 ? (
                      user.isAdmin >= 1 ? (
                        <TableUser
                          users={users}
                          done={done}
                          setFetchAll={setFetchAll}
                          answers={answers}
                          customers={customers}
                          setTriggerUsers={setTriggerUsers}
                          triggerUsers={triggerUsers}
                          isAdmin={user.isAdmin}
                          addZero={addZero}
                          handleClose={handleClose}
                          setOpenSnackbar={setOpenSnackbar}
                          setSnackbarMsg={setSnackbarMsg}
                          setSnackbarSeverity={setSnackbarSeverity}
                          API_PATH={API_PATH}
                          data_users={customersAnswers}
                        />
                      ) : null
                    ) : (
                      <Typography variant="h4" align="center">
                        Keine Benutzerdaten
                      </Typography>
                    )}
                  </Route>
                  <Route path="/signUp">
                    <SignUp
                      handleClose={handleClose}
                      addZero={addZero}
                      setOpenSnackbar={setOpenSnackbar}
                      setSnackbarMsg={setSnackbarMsg}
                      setSnackbarSeverity={setSnackbarSeverity}
                      API_PATH={API_PATH}
                    />
                  </Route>
                </Switch>
                {/* </Paper> */}
              </Grid>
            </Grid>
          </Container>
        </main>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={snackbarSeverity}>
            {snackbarMsg}
          </Alert>
        </Snackbar>
      </div>
    </Router>
  );
}
