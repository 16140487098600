import React from "react";

import axios from "axios";
import { forwardRef, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropzoneArea } from "material-ui-dropzone";

import Questions from "./Questions";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

export default function Import(props) {
  const classes = useStyles();
  const { useState } = React;
  const [uploadDone, setUploadDone] = React.useState(false);
  const [settings, setSettings] = React.useState({});
  const [csvData, setCsvData] = React.useState([]);
  const [imageData, setImageData] = React.useState([]);
  const [isValid, setIsValid] = React.useState(false);

  useEffect(() => {
    if (settings.category_id && settings.author) {
      setIsValid(true);
    } else setIsValid(false);
  }, [settings]);

  const displayCSV = (f) => {
    if (f) {
      const reader = new FileReader();
      reader.onload = function () {
        var csv = reader.result;
        var lines = csv.split("\n");
        var result = [];
        var headers = lines[0].split(";");

        for (let i = 0; i < headers.length; i++) {
          const element = headers[i];
          headers[i] = element.replace("\r", "");
        }
        //setColumns(headers)
        //console.log(headers);
        for (var i = 1; i < lines.length; i++) {
          var obj = {};
          var currentline = lines[i].split(";");
          for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
          }
          //   obj.software_id = settings.software_id
          //     ? settings.software_id.id
          //     : null;
          //   obj.software_version_id = settings.software_version_id
          //     ? settings.software_version_id.id
          //     : null;
          //   obj.modul_id = settings.modul_id ? settings.modul_id.id : null;
          obj.category_id = settings.category_id
            ? settings.category_id.id
            : null;
          obj.author = settings.author;

          result.push(obj);
        }
        setCsvData(result);
        setUploadDone(true);
        props.setSnackbarMsg("CSV importiert");
      };
      reader.readAsText(f);
    }
  };

  const deleteCSV = () => {
    setUploadDone(false);
    setCsvData([]);
    //setSettings({})
  };

  const uploadCSV = () => {
    // let questionData = new FormData()
    csvData.forEach((csvQuestion) => {
      let img = undefined;
      delete csvQuestion.tableData;
      // questionData = csvQuestion
      imageData.forEach((image) => {
        if (image.name === csvQuestion.image) {
          img = image;
        }
      });
      if (img) {
        let image = new FormData();
        image.append("file", img);
        axios
          .post(props.API_PATH + "upload", image)
          .then(function (response) {
            csvQuestion.image = response.data.name;
            axios
              .post(props.API_PATH + "questions", csvQuestion)
              .then(function () {
                props.setOpenSnackbar(true);
                props.setFetchAll(true);
                props.setSnackbarSeverity("success");
                props.setSnackbarMsg("Frage erfolgreich erstellt!");
              })
              .catch(function (err) {
                console.error(err);
                console.log("error");
                console.log(err);
                props.setOpenSnackbar(true);
                props.setSnackbarSeverity("error");
                props.setSnackbarMsg("Fehler");
              });
          })
          .catch(function (err) {
            console.error(err);
            console.log("error image");
            console.log(err);
            props.setOpenSnackbar(true);
            props.setSnackbarSeverity("error");
            props.setSnackbarMsg("Bild Upload fehlgeschlagen");
          });
      } else {
        axios
          .post(props.API_PATH + "questions", csvQuestion)
          .then(function () {
            props.setOpenSnackbar(true);
            props.setFetchAll(true);
            props.setSnackbarSeverity("success");
            props.setSnackbarMsg("Frage erfolgreich erstellt!");
          })
          .catch(function (err) {
            console.error(err);
            props.setOpenSnackbar(true);
            props.setSnackbarSeverity("error");
            props.setSnackbarMsg("Fehler");
          });
      }
    });
    deleteCSV();
    props.setOpenSnackbar(true);
    props.setSnackbarSeverity("success");
    props.setSnackbarMsg("Fragen hochgeladen");
  };

  const uploadImages = () => {
    let images = new FormData();
    // axios
    //     .post(props.API_PATH + 'uploadMukkti', images)
    //     .then(() => {
    //         props.setOpenSnackbar(true)
    //         props.setSnackbarSeverity('success')
    //         props.setSnackbarMsg('Bilder hochgeladen')
    //     })
    //     .catch(err => {
    //         console.error(err);
    //         props.setOpenSnackbar(true)
    //         props.setSnackbarSeverity('error')
    //         props.setSnackbarMsg('Fehler')
    //     });
    // deleteCSV()
    // props.setOpenSnackbar(true)
    // props.setSnackbarSeverity('success')
    // props.setSnackbarMsg('Fragen hochgeladen')
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={classes.paper} elevation={3}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <Typography variant="h5" gutterBottom>
                Fragen Importieren
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="primary"
                href={process.env.PUBLIC_URL + "/sample_csv.csv"}
                download
              >
                Download CSV Beispiel Datei
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Einstellungen</Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                {/* <Grid item xs={6}>
                  <Autocomplete
                    id="size-small-filled-multi"
                    value={settings.software_id}
                    onChange={(event, newValue) => {
                      setSettings({ ...settings, software_id: newValue });
                    }}
                    options={props.software}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          !settings.software_id && !props.isValid ? true : false
                        }
                        variant="outlined"
                        required
                        label="Software wählen"
                        placeholder="Software wählen"
                      />
                    )}
                  />
                </Grid> */}
                {/* <Grid item xs={6}>
                  <Autocomplete
                    id="size-small-filled-multi"
                    value={settings.software_version_id}
                    onChange={(event, newValue) => {
                      setSettings({
                        ...settings,
                        software_version_id: newValue,
                      });
                    }}
                    options={props.softwareVersions}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          !settings.software_version_id && !props.isValid
                            ? true
                            : false
                        }
                        variant="outlined"
                        required
                        label="Softwareversion wählen"
                        placeholder="Softwareversion wählen"
                      />
                    )}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <Autocomplete
                    id="size-small-filled-multi"
                    value={settings.category_id}
                    onChange={(event, newValue) => {
                      setSettings({ ...settings, category_id: newValue });
                    }}
                    options={props.categories}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          !settings.category_id && !props.isValid ? true : false
                        }
                        variant="outlined"
                        required
                        label="Kategorie wählen"
                        placeholder="Kategorie wählen"
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <Autocomplete
                    id="size-small-filled-multi"
                    value={settings.modul_id}
                    onChange={(event, newValue) => {
                      setSettings({ ...settings, modul_id: newValue });
                    }}
                    options={props.moduls}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          !settings.modul_id && !props.isValid ? true : false
                        }
                        variant="outlined"
                        required
                        label="Modul wählen"
                        placeholder="Modul wählen"
                      />
                    )}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    id="author"
                    name="author"
                    label="Autor"
                    variant="outlined"
                    error={
                      !settings.author > 0 && !props.isValid ? true : false
                    }
                    multiline={true}
                    fullWidth
                    value={settings.author}
                    onChange={(event, newValue) => {
                      setSettings({ ...settings, author: event.target.value });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              {isValid ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <DropzoneArea
                      filesLimit={1}
                      acceptedFiles={[".csv"]}
                      dropzoneText={"CSV Datei auswählen"}
                      onChange={(files) => displayCSV(files[0])}
                      onDelete={() => deleteCSV()}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DropzoneArea
                      showPreviews={true}
                      showPreviewsInDropzone={false}
                      useChipsForPreview
                      previewGridProps={{
                        container: { spacing: 1, direction: "row" },
                      }}
                      previewChipProps={{
                        classes: { root: classes.previewChip },
                      }}
                      previewText="Selected files"
                      filesLimit={50}
                      acceptedFiles={["image/*"]}
                      dropzoneText={"Bilder hinzufügen"}
                      onChange={(files) => setImageData(files)}
                      onAlert={(message, variant) =>
                        console.log(`${variant}: ${message}`)
                      }
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => uploadCSV()}
                size="large"
                fullWidth
                variant="outlined"
                color="primary"
                startIcon={<CloudUploadIcon />}
                disabled={uploadDone ? false : true}
              >
                {csvData.length} Fragen bereit zum Upload
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {uploadDone ? (
          <Paper elevation={3} className={classes.paper}>
            <Questions
              isImport={true}
              setFetchAll={props.setFetchAll}
              makeBold={props.makeBold}
              isAdmin={props.isAdmin}
              addZero={props.addZero}
              handleClose={props.handleClose}
              setOpenSnackbar={props.setOpenSnackbar}
              setSnackbarMsg={props.setSnackbarMsg}
              setSnackbarSeverity={props.setSnackbarSeverity}
              API_PATH={props.API_PATH}
              questions={csvData}
              software={props.software}
              softwareVersions={props.softwareVersions}
              moduls={props.moduls}
              categories={props.categories}
              setupData={props.setupData}
            />
          </Paper>
        ) : null}
      </Grid>
    </Grid>
  );
}
