import React from "react";
import { useState, useRef, useEffect } from "react";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";

import { forwardRef } from "react";
import axios from "axios";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";

import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  image: {
    position: "relative",
    height: 60,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 60,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    maxHeight: "100%",
    maxWidth: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  colorDot: {
    padding: "5px",
    marginLeft: "calc(50% - 15px)",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    color: "white",
  },
  colorPicker: {
    width: "80%",
    height: "40px",
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function SignUp(props) {
  const classes = useStyles();
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [isAdmin, setIsAdmin] = React.useState("0");
  const [fetchLogins, setFetchLogins] = React.useState(true);
  const [logins, setLogins] = React.useState([]);

  const [isValid, setIsValid] = React.useState(false);

  const validateEmail = (email) => {
    var regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };
  const validateTextfield = (input) => {
    if (input.length >= 2) {
      return true;
    } else return false;
  };

  const validatePassword = (input) => {
    var lowerCaseLetters = /[a-z]/g;
    var upperCaseLetters = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (
      input.length >= 8 &&
      input.match(lowerCaseLetters) &&
      input.match(upperCaseLetters) &&
      input.match(numbers)
    ) {
      return true;
    } else return false;
  };

  const history = useHistory();

  useEffect(() => {
    if (
      validateTextfield(username) &&
      validateEmail(email) &&
      validatePassword(password) &&
      validatePassword(password2)
    ) {
      setIsValid(true);
    } else setIsValid(false);
  }, [name, surname, username, email, password, password2]);

  useEffect(() => {
    if (fetchLogins) {
      axios
        .get(props.API_PATH + "users/getList_test")
        .then((response) => {
          setLogins(response.data.userData);
          setFetchLogins(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [fetchLogins]);

  const setRoles = (role) => {
    if (role === 0) {
      return "Manager";
    }
    if (role === 1) {
      return "Admin";
    }
    if (role === 2) {
      return "Superadmin";
    }
  };

  const [columns, setColumns] = useState([
    { title: "ID", field: "id", editable: "never", width: "10" },
    { title: "Name", field: "name" },
    { title: "Nachname", field: "surname" },
    { title: "Benutzername", field: "username" },
    { title: "E-Mail", field: "email" },
    {
      title: "Rolle",
      field: "user_type_id",
      render: (rowData) => <p>{setRoles(rowData.user_type_id)}</p>,
    },
    {
      render: (rowData) => (
        <p>
          {props.addZero(new Date(rowData.created_at).getDate()) +
            "." +
            props.addZero(new Date(rowData.created_at).getMonth() + 1) +
            "." +
            new Date(rowData.created_at).getFullYear() +
            " " +
            props.addZero(new Date(rowData.created_at).getHours()) +
            ":" +
            props.addZero(new Date(rowData.created_at).getMinutes()) +
            ":" +
            props.addZero(new Date(rowData.created_at).getSeconds())}
        </p>
      ),
      title: "Erstelldatum",
      field: "created_at",
      editable: "never",
    },
  ]);

  const resetForm = () => {
    setName("");
    setSurname("");
    setUsername("");
    setPassword("");
    setPassword2("");
    setIsAdmin("");
    setEmail("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let loginData = new FormData();
    loginData.append("name", name);
    loginData.append("surname", surname);
    loginData.append("username", username);
    loginData.append("email", email);
    loginData.append("user_type_id", isAdmin);
    if (password === password2) {
      loginData.append("password", password);
      if (logins.some((login) => email === login.email)) {
        props.setSnackbarMsg("E-Mail Adresse bereits in Benutzung");
        props.setOpenSnackbar(true);
        props.setSnackbarSeverity("error");
      } else {
        axios
          .post(props.API_PATH + "login/new", loginData)
          .then(function (response) {
            resetForm();
            props.setOpenSnackbar(true);
            setFetchLogins(true);
            props.setSnackbarSeverity("success");
            props.setSnackbarMsg("Login erstellt");
          })
          .catch(function (error) {
            console.error(error);
            let err = error.toString();
            props.setSnackbarMsg("Fehler");
            props.setOpenSnackbar(true);
            props.setSnackbarSeverity("error");
          });
      }
    } else {
      props.setSnackbarMsg("Passwörter stimmen nicht überein");
      props.setOpenSnackbar(true);
      props.setSnackbarSeverity("error");
    }
  };

  const removeData = (ids) => {
    axios
      .delete(`${props.API_PATH}deleteMulti/logins/id/(${ids})`)
      .then(function (response) {
        props.setOpenSnackbar(true);
        setFetchLogins(true);
        props.setSnackbarSeverity("success");
        props.setSnackbarMsg("Login(s) gelöscht");
      })
      .catch(function (error) {
        console.error(error);
        props.setOpenSnackbar(true);
        props.setSnackbarSeverity("error");
        props.setSnackbarMsg("Fehler");
      });
  };

  const onSave = (id, data) => {
    axios
      .post(props.API_PATH + "logins/id/" + id, data)
      .then(function (response) {
        setFetchLogins(true);
        props.setOpenSnackbar(true);
        props.setSnackbarSeverity("success");
        props.setSnackbarMsg("Login aktualisiert");
      })
      .catch(function (error) {
        console.error(error);
        props.setOpenSnackbar(true);
        props.setSnackbarSeverity("error");
        props.setSnackbarMsg("Fehler");
      });
  };

  return (
    <Grid container justify="center" spacing={3}>
      <Grid item xs={4}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h5" gutterBottom>
            Login Erstellen
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Geben Sie die Daten ein und drücken Sie auf absenden.
          </Typography>
          <br></br>
          <form className={""} noValidate autoComplete="off">
            <Grid container justify="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  fullWidth
                  id="firstName"
                  label="Name"
                  autoFocus
                  // error={!validateTextfield(name)}
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="surname"
                  label="Nachname"
                  name="surname"
                  autoComplete="lname"
                  // error={!validateTextfield(surname)}
                  value={surname}
                  onChange={(event) => {
                    setSurname(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="username"
                  label="Benutzername"
                  name="username"
                  autoComplete="uname"
                  error={!validateTextfield(username)}
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="E-Mail Adresse"
                  name="email"
                  autoComplete="email"
                  error={!validateEmail(email)}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Tooltip title="8 Zeichen, min. ein Großbuchstabe und eine Zahl">
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Passwort"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={!validatePassword(password)}
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title="8 Zeichen, min. ein Großbuchstabe und eine Zahl">
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Passwort wiederholen"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={!validatePassword(password2)}
                    value={password2}
                    onChange={(event) => {
                      setPassword2(event.target.value);
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item align="left" xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="outlined-label">Benutzerrolle</InputLabel>
                  <Select
                    labelId="outlined-label"
                    id="select-outlined"
                    onChange={(event) => setIsAdmin(event.target.value)}
                    label="Benutzerrolle"
                    value={isAdmin}
                  >
                    <MenuItem key={"select0"} value={"0"}>
                      {"Manager"}
                    </MenuItem>
                    <MenuItem key={"select1"} value={"1"}>
                      {"Admin"}
                    </MenuItem>
                    <MenuItem key={"select2"} value={"2"}>
                      {"Superadmin"}
                    </MenuItem>
                    <MenuItem key={"select3"} value={"3"}>
                      {"Viewer"}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button
                  color="primary"
                  size="large"
                  variant="outlined"
                  disabled={!isValid}
                  onClick={handleSubmit}
                  className={""}
                  type="submit"
                >
                  <Check></Check>Absenden
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h5" gutterBottom>
            Logins
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Sie können die Tabelle sortieren, durchsuchen, filtern und
            bearbeiten.
          </Typography>
          <MaterialTable
            title=""
            columns={columns}
            data={logins}
            icons={tableIcons}
            options={{
              filtering: true,
              selection: true,
            }}
            actions={[
              {
                tooltip: "Remove All Selected Logins",
                icon: tableIcons.Delete,
                onClick: (evt, data) => {
                  let idArray = [];
                  data.forEach((value) => {
                    idArray.push(value.id);
                  });
                  removeData(idArray.toString());
                },
              },
            ]}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = { name: newData.name };
                    onSave(oldData.id, dataUpdate);
                    resolve();
                  }, 1000);
                }),
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

// import React from 'react';
// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import FormControl from '@material-ui/core/FormControl';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
// import axios from 'axios';
// import Paper from '@material-ui/core/Paper';

// import { useHistory } from "react-router-dom";

// const useStyles = makeStyles((theme) => ({
//     paper: {
//         marginTop: theme.spacing(3),
//         marginBottom: theme.spacing(3),
//         padding: theme.spacing(2),
//         [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
//             marginTop: theme.spacing(6),
//             marginBottom: theme.spacing(6),
//             padding: theme.spacing(3),
//         },
//     },
//     avatar: {
//         margin: theme.spacing(1),
//         backgroundColor: theme.palette.secondary.main,
//     },
//     form: {
//         width: '100%', // Fix IE 11 issue.
//         marginTop: theme.spacing(3),
//     },
//     submit: {
//         margin: theme.spacing(3, 0, 2),
//     },
// }));

// export default function SignUp(props) {
//     const classes = useStyles();
//     const [name, setName] = React.useState("");
//     const [surname, setSurname] = React.useState("");
//     const [username, setUsername] = React.useState("");
//     const [email, setEmail] = React.useState("");
//     const [password, setPassword] = React.useState("");
//     const [password2, setPassword2] = React.useState("");
//     const [isAdmin, setIsAdmin] = React.useState('0');
//     const [logins, setLogins] = React.useState([]);

//     const history = useHistory();

//     axios
//         .get(props.API_PATH + 'users/getList')
//         .then((response) => {
//             setLogins(response);
//             console.log(response);
//         })
//         .catch(err => {
//             console.error(err);
//         });

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         let user_type_id = isAdmin
//         if (password && password2 && name && surname && username && email) {
//             if (password === password2 && name && surname && username && email) {
//                 let userObject = {
//                     name, surname, username, email, password, user_type_id
//                 }

//                 let userData = new FormData()
//                 userData = userObject
//                 axios
//                     .post(props.API_PATH + 'login/new', userData)
//                     .then(() => {
//                         console.log('User Created')
//                         props.setOpenSnackbar(true)
//                         props.setSnackbarSeverity('success')
//                         props.setSnackbarMsg('Benutzer erstellt!')
//                         setTimeout(() => {
//                             history.push("/dashboard");
//                         }, 1000);
//                     })
//                     .catch(err => {
//                         console.error(err);
//                         props.setOpenSnackbar(true)
//                         props.setSnackbarSeverity('error')
//                         props.setSnackbarMsg('Fehler')
//                     });
//             }
//             else {
//                 props.setOpenSnackbar(true)
//                 props.setSnackbarSeverity('error')
//                 props.setSnackbarMsg('Passwörter stimmen nicht überein')
//             }
//         }
//         else {
//             props.setOpenSnackbar(true)
//             props.setSnackbarSeverity('error')
//             props.setSnackbarMsg('Pflichtfelder bitte ausfüllen')

//         }
//     }

//     const handleChange = (name) => (event) => {
//         event.preventDefault()
//         if (name === "role") {
//             setIsAdmin(event.target.value)
//         }
//     }

//     return (
//         <Container component="main" >
//             <Grid container justify='center' spacing={3}>
//                 <Grid item xs={12} md={4}>
//                     <Paper className={classes.paper} elevation={3}>
//                         <Typography component="h1" variant="h5">
//                             Login erstellen
//                         </Typography>
//                         <br></br>

//                         <form noValidate>-
//                             <Grid item xs={12} sm={6}>
//                                 <TextField
//                                     autoComplete="fname"
//                                     name="firstName"
//                                     variant="outlined"
//                                     required
//                                     fullWidth
//                                     id="firstName"
//                                     label="Name"
//                                     autoFocus
//                                     value={name}
//                                     onChange={event => { setName(event.target.value) }}
//                                 />
//                             </Grid>
//                             <Grid item xs={12} sm={6}>
//                                 <TextField
//                                     variant="outlined"
//                                     required
//                                     fullWidth
//                                     id="surname"
//                                     label="Nachname"
//                                     name="surname"
//                                     autoComplete="lname"
//                                     value={surname}
//                                     onChange={event => { setSurname(event.target.value) }}
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     variant="outlined"
//                                     required
//                                     fullWidth
//                                     id="username"
//                                     label="Benutzername"
//                                     name="username"
//                                     autoComplete="uname"
//                                     value={username}
//                                     onChange={event => { setUsername(event.target.value) }}
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     variant="outlined"
//                                     required
//                                     fullWidth
//                                     id="email"
//                                     label="E-Mail Adresse"
//                                     name="email"
//                                     autoComplete="email"
//                                     value={email}
//                                     onChange={event => { setEmail(event.target.value) }}
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     variant="outlined"
//                                     required
//                                     fullWidth
//                                     name="password"
//                                     label="Passwort"
//                                     type="password"
//                                     id="password"
//                                     autoComplete="current-password"
//                                     value={password}
//                                     onChange={event => { setPassword(event.target.value) }}
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     variant="outlined"
//                                     required
//                                     fullWidth
//                                     name="password"
//                                     label="Passwort wiederholen"
//                                     type="password"
//                                     id="password"
//                                     autoComplete="current-password"
//                                     value={password2}
//                                     onChange={event => { setPassword2(event.target.value) }}
//                                 />
//                             </Grid>
//                             <Grid item align='left' xs={12}>
//                                 <FormControl fullWidth variant="outlined" >
//                                     <InputLabel id="outlined-label">Benutzerrolle</InputLabel>
//                                     <Select
//                                         labelId="outlined-label"
//                                         id="select-outlined"
//                                         onChange={handleChange('role')}
//                                         label="Benutzerrolle"
//                                         value={isAdmin}
//                                     >
//                                         <MenuItem key={'select0'} value={'0'}>{'Manager'}</MenuItem>
//                                         <MenuItem key={'select1'} value={'1'}>{'Admin'}</MenuItem>
//                                         <MenuItem key={'select2'} value={'2'}>{'Superadmin'}</MenuItem>
//                                     </Select>
//                                 </FormControl>
//                             </Grid>
//                             <Button
//                                 type="submit"
//                                 fullWidth
//                                 variant="outlined"
//                                 size="large"
//                                 color="primary"
//                                 className={classes.submit}
//                                 onClick={handleSubmit}
//                             >
//                                 Benutzer erstellen
//                             </Button>
//                         </form>
//                     </Paper>
//                 </Grid>
//                 <Grid item xs={12} md={8}>
//                     <Paper className={classes.paper} elevation={3}>
//                     </Paper>

//                 </Grid>
//             </Grid>
//         </Container>
//     );
// }
