import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";

//import Login from './pages/Login';
import Dashboard from "./pages/Dashboard";
import Footer from "./pages/Footer";
import Signin from "./pages/Signin";

import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";

import { verifyTokenAsync } from "./asyncActions/authAsyncActions";

import bg from "./hypnotize.png";

function App() {
  const authObj = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { authLoading, isAuthenticated } = authObj;

  // verify token on app load
  useEffect(() => {
    dispatch(verifyTokenAsync());
  }, []);

  // checking authentication
  if (authLoading) {
    return <div className="content">Checking Authentication...</div>;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <div
          style={{
            position: "absolute",
            width: " 100%",
            height: "100%",
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "repeat",
          }}
        >
          <div className="content">
            <Switch>
              <PublicRoute
                path="/login"
                component={Signin}
                isAuthenticated={isAuthenticated}
              />
              <PrivateRoute
                path="/"
                component={Dashboard}
                isAuthenticated={isAuthenticated}
              />
              <Redirect to={isAuthenticated ? "/" : "/login"} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
