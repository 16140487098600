import React from "react";
import MaterialTable from "material-table";

import { forwardRef } from "react";
import axios from "axios";

import MailIcon from "@material-ui/icons/Mail";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { red, green } from "@material-ui/core/colors";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

const tableIcons = {
  // Mail: forwardRef((props, ref) => <MailIcon {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function TableUser(props) {
  const { useState } = React;
  const classes = useStyles();

  React.useEffect(() => {
    props.setTriggerUsers(true);
  }, []);

  const parseToJson = (points) => {
    if (points) {
      if (points === "Schulung") {
        return (
          <div style={{ width: "200px ", whiteSpace: "break-spaces" }}>
            <p>Schulung gewünscht!</p>
          </div>
        );
      } else {
        let jsonPoints = JSON.parse(points);
        let pointsCell = "";
        jsonPoints.forEach((pointsEl) => (
          <p>
            {
              (pointsCell +=
                pointsEl.name +
                ": " +
                pointsEl.points +
                "/" +
                pointsEl.questions_length +
                " \n\r")
            }
          </p>
        ));

        return (
          <div style={{ width: "200px ", whiteSpace: "break-spaces" }}>
            {pointsCell}
          </div>
        );
      }
    }
  };

  const parseToPercent = (points) => {
    if (points) {
      if (points === "Schulung") {
        return (
          <div style={{ width: "200px ", whiteSpace: "break-spaces" }}>
            <p>Schulung gewünscht!</p>
          </div>
        );
      } else {
        let jsonPoints = JSON.parse(points);
        let pointsCell = "";
        jsonPoints.forEach((pointsEl) => (
          <p>
            {
              (pointsCell +=
                pointsEl.name +
                ": " +
                (pointsEl.questions_length == 0
                  ? "0"
                  : (
                      (100 * pointsEl.points) /
                      pointsEl.questions_length
                    ).toFixed()) +
                " %  \n\r")
            }
          </p>
        ));

        return (
          <div style={{ width: "200px ", whiteSpace: "break-spaces" }}>
            {pointsCell}
          </div>
        );
      }
    }
  };
  const setActiveAvatar = (active) => {
    if (active === 0) {
      return "red";
    }
    if (active === 1) {
      return "orange";
    }
    if (active === 2) {
      return "grey";
    }
    if (active === 3) {
      return "blue";
    }
    if (active === 4) {
      return "green";
    }
  };
  var stringToColour = function (str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  };

  const [columns, setColumns] = useState([
    {
      title: "Id",
      field: "id",
      editable: "never",
      render: (rowData) => (
        <Tooltip title="Eine Firma, eine Farbe!">
          <Avatar
            style={{ backgroundColor: stringToColour(rowData.session_id) }}
          >
            {rowData.id}
          </Avatar>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      field: "active",
      render: (rowData) => (
        <Tooltip title="Rot = Gesperrt, Orange = Verifiziert, Grau = Freigegeben, Blau = Gestartet, Grün = Abgeschlossen">
          <Avatar
            style={{ backgroundColor: setActiveAvatar(rowData.active) }}
          />
        </Tooltip>
      ),
      editComponent: (props) => (
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            label="active"
          >
            <MenuItem value={0}>Gesperrt</MenuItem>
            <MenuItem value={2}>Freigegeben</MenuItem>
            <MenuItem value={4}>Abgeschlossen</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    { title: "Vorname", field: "name", editable: "never" },
    { title: "Nachname", field: "surname", editable: "never" },
    {
      title: "Punkte",
      field: "points",
      render: (rowData) => parseToJson(rowData.points),
      editComponent: (props) => (
        <div>
          <TextField
            multiline
            type="number"
            value={props.rowData.points}
            size="medium"
            variant="outlined"
            onChange={(e) => props.onChange(e.target.value)}
          />
        </div>
      ),
    },
    {
      title: "Punkte (%)",
      field: "percent",
      editable: "never",
      render: (rowData) => parseToPercent(rowData.points),
    },
    { title: "Fragenanzahl", field: "questions_length", editable: "never" },
    { title: "Dauer (sec)", field: "duration", editable: "never" },
    { title: "E-Mail", field: "email", editable: "never" },
    { title: "Firma", field: "company_name", editable: "never" },
    {
      title: "Ansprechpartner Firma",
      field: "company_booker_name",
      editable: "never",
    },
    {
      title: "Ansprechpartner E-Mail",
      field: "company_email",
      editable: "never",
    },
    {
      title: "Erstelldatum",
      field: "created_at",
      editable: "never",
      render: (rowData) => (
        <p>
          {props.addZero(new Date(rowData.created_at).getDate()) +
            "." +
            props.addZero(new Date(rowData.created_at).getMonth() + 1) +
            "." +
            new Date(rowData.created_at).getFullYear() +
            " " +
            props.addZero(new Date(rowData.created_at).getHours()) +
            ":" +
            props.addZero(new Date(rowData.created_at).getMinutes()) +
            ":" +
            props.addZero(new Date(rowData.created_at).getSeconds())}
        </p>
      ),
    },
  ]);

  const removeData = (ids) => {
    axios
      .delete(`${props.API_PATH}deleteMulti/customers/id/(${ids})`)
      .then(() => {
        props.setOpenSnackbar(true);
        props.setTriggerUsers(true);
        props.setSnackbarSeverity("success");
        props.setSnackbarMsg("Benutzer gelöscht");
      })
      .catch((err) => {
        console.error(err);
        props.setOpenSnackbar(true);
        props.setSnackbarSeverity("error");
        props.setSnackbarMsg("Fehler");
      });
  };

  const onSave = (id, dataAnswer, dataCustomer) => {
    axios
      // .post(props.API_PATH + 'customers/customer_id/' + id, dataCustomer)
      .post(props.API_PATH + "update/customers/", {
        active: dataCustomer.active,
        id: id,
      })
      .then(() => {
        if (dataAnswer.points !== undefined) {
          axios.post(props.API_PATH + "update/answers/", {
            points: dataAnswer.points,
            id: id,
          });
          props.setOpenSnackbar(false);
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("success");
          props.setSnackbarMsg("Benutzer aktualisiert");
          props.setTriggerUsers(true);
          props.setFetchAll(true);
        }
      })
      .catch((err) => {
        console.error(err);
        props.setOpenSnackbar(false);
        props.setOpenSnackbar(true);
        props.setSnackbarSeverity("error");
        props.setSnackbarMsg("Fehler");
      });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={classes.paper} elevation={3}>
          <Typography variant="h4" gutterBottom>
            Teilnehmer
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Sie können die Daten sortieren, durchsuchen, filtern und bearbeiten.
          </Typography>
          {props.data_users.length == 0 ? null : (
            <MaterialTable
              title=""
              columns={columns}
              data={props.data_users}
              icons={tableIcons}
              options={{
                filtering: true,
                selection: false,
                exportButton: true,
                exportAllData: true,
                // tableLayout: "fixed"
              }}
              actions={
                props.isAdmin !== 3
                  ? [
                      {
                        icon: MailIcon,
                        tooltip:
                          "Benutzer Freischalten und Link zum Test senden",
                        onClick: async (event, rowData) => {
                          axios
                            .get(
                              `${props.API_PATH}resentLinkToTest/(${rowData.id})`
                            )
                            .then((resp) => {
                              props.setOpenSnackbar(false);
                              props.setOpenSnackbar(true);
                              props.setTriggerUsers(true);
                              props.setSnackbarSeverity("success");
                              props.setSnackbarMsg("E-Mail versendet");
                              props.setFetchAll(true);
                            })
                            .catch((err) => {
                              console.error(err);
                              props.setOpenSnackbar(false);
                              props.setOpenSnackbar(true);
                              props.setSnackbarSeverity("error");
                              props.setSnackbarMsg("Fehler");
                            });
                        },
                      },
                      {
                        icon: DeleteOutline,
                        tooltip: "Benutzer Löschen",
                        onClick: async (event, rowData) => {
                          axios
                            .delete(
                              `${props.API_PATH}deleteCustomer/(${rowData.id})`
                            )
                            .then(() => {
                              props.setOpenSnackbar(false);
                              props.setOpenSnackbar(true);
                              props.setTriggerUsers(true);
                              props.setSnackbarSeverity("success");
                              props.setSnackbarMsg("Benutzer gelöscht");
                              props.setFetchAll(true);
                            })
                            .catch((err) => {
                              console.error(err);
                              props.setOpenSnackbar(false);
                              props.setOpenSnackbar(true);
                              props.setSnackbarSeverity("error");
                              props.setSnackbarMsg("Fehler");
                            });
                        },
                      },
                    ]
                  : []
              }
              editable={
                props.isAdmin !== 3
                  ? {
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          onSave(
                            oldData.customer_id,
                            {
                              points:
                                newData.points === oldData.points
                                  ? oldData.points
                                  : newData.points,
                            },
                            {
                              active:
                                newData.active === oldData.active
                                  ? oldData.active
                                  : newData.active,
                            }
                          );
                          resolve();
                        }),
                    }
                  : {}
              }
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
