import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userLoginAsync } from "./../asyncActions/authAsyncActions";

import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// custom hook to manage the form input
const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default function SignIn() {
  const classes = useStyles();
  const authObj = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { userLoginLoading, loginError } = authObj;
  const email = useFormInput("");
  const password = useFormInput("");
  const [open, setOpen] = React.useState(false);
  const [snachbarText, setSnachbarText] = React.useState("");

  // handle button click of login form
  const handleLogin = (event) => {
    event.preventDefault();
    dispatch(userLoginAsync(email.value, password.value));
  };

  useEffect(() => {
    if (loginError) {
      if (loginError.length > 0) {
        setSnachbarText(loginError);
        setOpen(true);
      }
    }
  }, [loginError, userLoginLoading]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            alt=""
            src={
              "https://graphisoft-nord.de/wp-content/uploads/2021/02/GraphisoftNordGmbH.png"
            }
          ></img>
          <br />
          <br />
          <br />
          <Typography component="h1" variant="h4">
            Einstufungstest
          </Typography>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              {...email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              {...password}
            />
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              size="large"
              color="primary"
              className={classes.submit}
              onClick={(event) => handleLogin(event)}
              disabled={userLoginLoading}
            >
              {userLoginLoading ? "Loading..." : "Login"}
            </Button>
            {/*
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Linker to={"/signup"} variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Linker>
                        </Grid>
                    </Grid>
                    */}
          </form>
          <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {snachbarText}
            </Alert>
          </Snackbar>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
