import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import MaterialTable from "material-table";
import React, { forwardRef, useRef, useState } from "react";
import EmailEditor from "react-email-editor";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Email = (props) => {
  const classes = useStyles();

  const emailEditorRef = useRef(null);
  const [name, setName] = useState("");

  React.useEffect(() => {
    setName("");
    props.setTemplate({ ...props.template, name: "" });
  }, []);

  const [columns, setColumns] = useState([
    {
      title: "Id",
      field: "id",
      editable: "never",
    },
    {
      title: "Template Name",
      field: "name",
    },
    {
      render: (rowData) => (
        <p>
          {props.addZero(new Date(rowData.created_at).getDate()) +
            "." +
            props.addZero(new Date(rowData.created_at).getMonth() + 1) +
            "." +
            new Date(rowData.created_at).getFullYear() +
            " " +
            props.addZero(new Date(rowData.created_at).getHours()) +
            ":" +
            props.addZero(new Date(rowData.created_at).getMinutes()) +
            ":" +
            props.addZero(new Date(rowData.created_at).getSeconds())}
        </p>
      ),
      title: "Erstelldatum",
      field: "created_at",
      editable: "never",
    },
  ]);

  const removeData = (ids) => {
    axios
      .delete(`${props.API_PATH}deleteMulti/questions/id/(${ids})`)
      .then((response) => {
        if (response.status === 200) {
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("success");
          props.setSnackbarMsg("Frage/n gelöscht");
          props.setFetchAll(true);
          setName("");
          props.setTemplate({ ...props.template, name: "" });
        } else {
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("error");
          props.setSnackbarMsg("Fehler");
        }
      });
  };

  const saveDesign = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      // console.log(design);
      // console.log(html);

      let newDesign = JSON.stringify(design);
      newDesign = btoa(newDesign);
      // let newDesign = btoa(design);
      let newHtml = btoa(html);
      let emailData = new FormData();
      emailData.append("name", props.template.name);
      emailData.append("html", newHtml);
      emailData.append("template", newDesign);
      axios
        .post(props.API_PATH + "create/email_template", emailData)
        .then(function (response) {
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("success");
          props.setSnackbarMsg("E-Mail Template gespeichert");
          props.setFetchAll(true);
          setName("");
          props.setTemplate({ ...props.template, name: "" });
        })
        .catch((err) => {
          console.error(err);
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("error");
          props.setSnackbarMsg("Fehler");
        });
    });
  };

  const updateDesign = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      // console.log(design);
      // console.log(JSON.stringify(design));
      let newDesign = JSON.stringify(design);
      newDesign = btoa(newDesign);
      let newHtml = btoa(html);
      let emailData = new FormData();
      emailData.append("id", props.template.id);
      emailData.append("name", props.template.name);
      emailData.append("html", newHtml);
      emailData.append("template", newDesign);
      axios
        .post(props.API_PATH + "update/email_template/", emailData)
        .then(function (response) {
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("success");
          props.setSnackbarMsg("E-Mail Template aktualisiert");
          props.setFetchAll(true);
          setName("");
          props.setTemplate({ ...props.template, name: "" });
        })
        .catch((err) => {
          console.error(err);
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("error");
          props.setSnackbarMsg("Fehler");
        });
    });
  };

  const isBase64 = (str) => {
    if (str === "" || str.trim() === "") {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };

  const handleSelectDesign = (event) => {
    setName(event.target.value.name);
    props.setTemplate(event.target.value);
    loadDesign(event.target.value.template);
  };

  const loadDesign = (design) => {
    let newDesign = design;
    if (isBase64(newDesign)) {
      newDesign = atob(newDesign);
    } else {
      newDesign = newDesign.replaceAll('style="', 'style=\\"');
      newDesign = newDesign.replaceAll('">', '\\">');
    }
    newDesign = JSON.parse(newDesign);
    emailEditorRef.current.editor.loadDesign(newDesign);
  };

  const deleteDesign = () => {
    axios
      .delete(props.API_PATH + "delete/email_template/id/" + props.template.id)
      .then(function (response) {
        props.setOpenSnackbar(true);
        props.setSnackbarSeverity("success");
        props.setSnackbarMsg("E-Mail Template gelöscht");
        props.setFetchAll(true);
      })
      .catch((err) => {
        console.error(err);
        props.setOpenSnackbar(true);
        props.setSnackbarSeverity("error");
        props.setSnackbarMsg("Fehler");
      });
  };

  const onReady = () => {
    // if (props.emailTemplate.length > 0) {
    //     loadDesign(template.template)
    // }
    emailEditorRef.current.editor.registerCallback(
      "image",
      function (file, done) {
        // Handle file upload here
        // console.log(file);

        if (file) {
          let imageData = new FormData();
          imageData.append("file", file.attachments[0]);
          axios
            .post(props.API_PATH + "upload", imageData)
            .then(function (response) {
              // console.log(response);
              done({ progress: 100, url: props.API_PATH + response.data.name });
              props.setOpenSnackbar(true);
              props.setFetchAll(true);
              props.setSnackbarSeverity("success");
              props.setSnackbarMsg("Bild Upload erfolgreich!");
            })
            .catch(function (err) {
              console.error(err);
              console.log("error image");
              console.log(err);
              props.setOpenSnackbar(true);
              props.setSnackbarSeverity("error");
              props.setSnackbarMsg("Bild Upload fehlgeschlagen");
            });
        } else {
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("error");
          props.setSnackbarMsg("Bild Upload fehlgeschlagen");
        }
      }
    );
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // unlayer.registerCallback('image', function(file, done) {
  //     var data = new FormData()
  //     data.append('file', file.attachments[0])

  //     fetch('/uploads', {
  //       method: 'POST',
  //       headers: {
  //         'Accept': 'application/json'
  //       },
  //       body: data
  //     }).then(response => {
  //       // Make sure the response was valid
  //       if (response.status >= 200 && response.status < 300) {
  //         return response
  //       } else {
  //         var error = new Error(response.statusText)
  //         error.response = response
  //         throw error
  //       }
  //     }).then(response => {
  //       return response.json()
  //     }).then(data => {
  //       // Pass the URL back to Unlayer to mark this upload as completed
  //       done({ progress: 100, url: data.filelink })
  //     })
  //   })
  return (
    <Grid container justify="center" spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3} className={classes.paper}>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                E-Mail Editor
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                E-Mail Templates erstellen
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Platzhalter
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Platzhalter werden benötigt um die Texte aus der Datenbank in
                den E-Mails verwenden zu können. Der Platzhalter wird vor dem
                Versenden einer E-Mail durch den Datenbankeintrag ersetzt.
                <br></br>
                <br></br>
              </Typography>
              <Button onClick={handleClickOpen}>Platzhalter anzeigen</Button>
              <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
                fullWidth={"sm"}
              >
                <DialogTitle id="simple-dialog-title">
                  Platzhalterzuweisung
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item xs={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        Platzhalter
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" gutterBottom>
                        Bedeutung
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        E-Mail-Templates
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        <strong>NAME</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Vorname Teilnehmer
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Benutzer E-Mail Bestätigung, Büro E-Mail Anmeldung,
                        Benutzer Quiz-Link, Benutzer Ergbenis
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        <strong>NACHNAME</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Nachname Teilnehmer
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Benutzer E-Mail Bestätigung, Büro E-Mail Anmeldung,
                        Benutzer Quiz-Link, Benutzer Ergbenis
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        <strong>EMAIL</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Benutzer E-Mail Adresse
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Benutzer E-Mail Bestätigung
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        <strong>FIRMA</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Name Firma
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Büro E-Mail Anmeldung, Büro Ergebnis
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        <strong>FIRMANAME</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Name der Person der Firma die gebucht hat
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Büro E-Mail Anmeldung, Büro Ergebnis
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        <strong>FIRMAEMAIL</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        E-Mail der Person von der Firma die gebucht hat
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Büro E-Mail Anmeldung, Büro Ergebnis
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        <strong>NUTZERLISTE</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Teilnehmerliste der Firma
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Büro E-Mail Anmeldung, Büro Ergebnis, Admin Info
                        Anmeldung, Admin Ergebnis
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        <strong>LINK</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Link zur Bestätigung und zum Quiz
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Benutzer Quiz-Link, Büro E-Mail Anmeldung
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        <strong>PUNKTELISTE</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Ergebnisse der Gruppenanmeldung
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Benutzer Ergebnis, Büro Ergebnis, Admin Ergebnis
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        <strong>DAUER</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Dauer des Quiz
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: 12 }} gutterBottom>
                        Benutzer Ergebnis, Büro Ergebnis, Admin Ergebnis
                      </Typography>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>

            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  Template
                </InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={props.template}
                  onChange={handleSelectDesign}
                  label="Template"
                >
                  {props.emailTemplate.length > 0 ? (
                    props.emailTemplate.map((template) => {
                      return (
                        <MenuItem value={template} key={"key" + template.id}>
                          {template.name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value="" disabled key={"empty"}>
                      Kein Template vorhanden
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Template Name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) =>
                  props.setTemplate({
                    ...props.template,
                    name: event.target.value,
                  })
                }
                value={props.template.name}
              />
            </Grid>
            <Grid item xs={2}>
              {props.template.name === name ? (
                <Button
                  fullWidth
                  color="primary"
                  onClick={updateDesign}
                  variant="outlined"
                  className={""}
                  size="large"
                  disabled={props.template.id > 0 ? false : true}
                >
                  Update Design
                </Button>
              ) : (
                <Button
                  fullWidth
                  color="primary"
                  onClick={saveDesign}
                  variant="outlined"
                  className={""}
                  size="large"
                >
                  Neues Design Speichern
                </Button>
              )}
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                color="secondary"
                onClick={() => {
                  emailEditorRef.current.editor.loadBlank({
                    backgroundColor: "#e7e7e7",
                  });
                }}
                variant="outlined"
                className={""}
                size="large"
                disabled={props.template.id > 0 ? false : true}
              >
                Design Leeren
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                color="secondary"
                onClick={deleteDesign}
                variant="outlined"
                className={""}
                size="large"
                disabled={props.template.id > 0 ? false : true}
              >
                Design Löschen
              </Button>
            </Grid>

            <Grid item xs={12}>
              <React.StrictMode>
                <EmailEditor ref={emailEditorRef} onReady={onReady} />
              </React.StrictMode>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* <Grid item xs={12}>
        <Paper elevation={3} className={classes.paper}>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                E-Mail Templates Tabelle
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MaterialTable
                title="Templates"
                columns={columns}
                data={props.emailTemplate}
                icons={tableIcons}
                options={{
                  filtering: true,
                  selection: true,
                  actionsCellStyle: {},
                }}
                actions={[
                  {
                    tooltip: "Ausgewählte Templates löschen",
                    icon: tableIcons.Delete,
                    onClick: (evt, data) => {
                      let idArray = [];
                      data.map(function (value, key, array) {
                        idArray.push(value.id);
                      });
                      removeData(idArray.toString());
                    },
                  },
                ]}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      delete newData.id;
                      delete newData.created_at;
                      delete newData.updated_at;
                      delete newData.deleted_at;
                      axios
                        .post(
                          props.API_PATH + "email_template/id/" + oldData.id,
                          newData
                        )
                        .then(() => {
                          props.setOpenSnackbar(true);
                          props.setSnackbarSeverity("success");
                          props.setSnackbarMsg("Templatename aktualisiert");
                          props.setFetchAll(true);
                        })
                        .then(() => resolve())
                        .catch((err) => {
                          console.error(err);
                          props.setOpenSnackbar(true);
                          props.setSnackbarSeverity("error");
                          props.setSnackbarMsg("Fehler");
                        });
                    }),
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid> */}
    </Grid>
  );
};

export default Email;
