import React from 'react';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        backgroundColor: '#ff6105',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 9999
    },
})

export default function Register(props) {
    const classes = useStyles();
    return (
        <Container className={classes.container}>
            <Breadcrumbs aria-label="breadcrumb" align='center' style={{ color: "white" }}>
                <Link color="inherit" href="/">
                    Copyright © {new Date().getFullYear()} GRAPHISOFT Nord GmbH
                </Link>
                <Link color="inherit" href="http://graphisoft-nord.de/">
                    Zu unserer Website
                </Link>
                <Link color="inherit" href="http://graphisoft-nord.de/datenschutz/">
                    Datenschutz
                </Link>
                <Link color="inherit" href="http://graphisoft-nord.de/impressum/">
                    Impressum
                </Link>
            </Breadcrumbs>
        </Container>
    );
}