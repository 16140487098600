import MaterialTable from "material-table";
import React from "react";
import { matchSorter } from "match-sorter";

import axios from "axios";
import { forwardRef, useEffect } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Pagination from "@material-ui/lab/Pagination";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

import Checkbox from "@material-ui/core/Checkbox";
import { green, red } from "@material-ui/core/colors";
import BlockIcon from "@material-ui/icons/Block";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Preview from "./Preview";
import QuestionUpdateForm from "./QuestionUpdateForm";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  VisibilityIcon: forwardRef((props, ref) => (
    <VisibilityIcon {...props} ref={ref} />
  )),
};

function PreviewQuestion(props) {
  return (
    <Dialog
      open={props.openPreview}
      onClose={props.handleClosePreview}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"lg"}
    >
      <DialogTitle id="alert-dialog-title">{"Vorschau"}</DialogTitle>
      <DialogContent>
        <Preview
          software={props.software}
          softwareVersions={props.softwareVersions}
          moduls={props.moduls}
          categories={props.categories}
          questionObj={props.questionObj}
          imagePath={props.API_PATH + props.questionObj.image}
          makeBold={props.makeBold}
          API_PATH={props.API_PATH}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={props.handleClosePreview}
          color="primary"
          autoFocus
        >
          zurück
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeleteQuestion(props) {
  return (
    <Dialog
      open={props.openDelete}
      onClose={props.handleCloseDelete}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">{"Frage Löschen"}</DialogTitle>
      <DialogContent>
        <Typography>Frage {props.questionObj.id} löschen</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            props.removeData([props.questionObj.id]);
            props.handleCloseDelete();
          }}
          color="primary"
          autoFocus
        >
          Frage löschen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function EditQuestion(props) {
  return (
    <Dialog
      open={props.openEdit}
      onClose={props.handleCloseEdit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"lg"}
    >
      <DialogTitle id="alert-dialog-title">{"Frage bearbeiten"}</DialogTitle>
      <DialogContent>
        <QuestionUpdateForm
          API_PATH={props.API_PATH}
          questionObj={props.questionObj}
          setQuestionObj={props.setQuestionObj}
          categories={props.categories}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={(event) => props.handleSubmit(event, props.questionObj)}
          color="primary"
          autoFocus
        >
          Update
        </Button>
        <Button
          variant="outlined"
          onClick={props.handleCloseEdit}
          color="secondary"
          autoFocus
        >
          zurück
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function searchQuestions(questions, q) {
  return matchSorter(questions, q, {
    keys: [
      "question",
      "answer_good",
      "answer_bad_1",
      "answer_bad_2",
      "category_name",
      "id",
    ],
  });
}

export default function Questions(props) {
  const { q, setQ, isAdmin } = props;
  const classes = useStyles();
  const { useState } = React;
  const [qImage, setQImage] = useState(null);
  const [questions, setQuestions] = React.useState([]);
  const [questionObj, setQuestionObj] = React.useState({});
  const [openPreview, setOpenPreview] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [fetchQuestion, setFetchQuestion] = React.useState(true);
  const API_PATH = process.env.REACT_APP_API_URL;
  // const [q, setQ] = useState("");
  const foundQuestions = searchQuestions(questions, q);

  useEffect(() => {
    if (fetchQuestion) {
      const requestQ = axios.get(API_PATH + "getAll/questions");
      axios
        .all([requestQ])
        .then(
          axios.spread((...responses) => {
            const responseQ = responses[0].data;
            // responseQ.sort((a, b) => b.id - a.id);
            setQuestions(responseQ);
          })
        )
        .catch((errors) => {
          // errors.
        });
      setFetchQuestion(false);
    }
  }, [fetchQuestion]);

  const handleOpenPreview = (data) => {
    setQuestionObj(data);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setQuestionObj({});
    setOpenPreview(false);
  };

  const handleOpenDelete = (data) => {
    setQuestionObj(data);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setQuestionObj({});
    setOpenDelete(false);
  };

  const handleOpenEdit = (data) => {
    setQuestionObj(data);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setQuestionObj({});
    setOpenEdit(false);
  };

  const handleSubmit = (event, obj) => {
    event.preventDefault();
    let file = null;
    if (obj.imageFile) {
      file = obj.imageFile[0]?.file;
      delete obj.imageFile;
    }
    delete obj.created_at;
    delete obj.updated_at;
    delete obj.deleted_at;
    delete obj.category_name;
    console.log(obj);
    let questionObject = obj;

    questionObject.software_id = 0;
    questionObject.software_version_id = 0;
    questionObject.modul_id = 0;

    // questionObject.software_id = questionObject.software_id
    //   ? questionObject.software_id.id
    //   : 0;
    // questionObject.software_version_id = questionObject.software_version_id
    //   ? questionObject.software_version_id.id
    //   : 0;
    // questionObject.modul_id = questionObject.modul_id
    //   ? questionObject.modul_id.id
    //   : 0;
    // questionObject.category_id = questionObject.category_id
    //   ? questionObject.category_id.id
    //   : 0;
    questionObject.approved
      ? (questionObject.approved = 1)
      : (questionObject.approved = 0);

    // questionObject.image = "";

    console.log(questionObject);

    let questionData = new FormData();

    questionData = questionObject;

    let imageData = new FormData();

    if (file) {
      imageData.append("file", file);
      axios
        .post(props.API_PATH + "upload", imageData)
        .then(function (response) {
          console.log(response.data.name);
          questionData.image = response.data.name;
          axios
            .post(props.API_PATH + "update/questions", questionData)
            .then(function () {
              props.setOpenSnackbar(true);
              props.setSnackbarSeverity("success");
              props.setSnackbarMsg("Frage erfolgreich bearbeitet!");
              setFetchQuestion(true);
              handleCloseEdit();
            })
            .catch(function (err) {
              console.error(err);
              console.log("error");
              console.log(err);
              props.setOpenSnackbar(true);
              props.setSnackbarSeverity("error");
              props.setSnackbarMsg("Fehler");
            });
        })
        .catch(function (err) {
          console.error(err);
          console.log("error image");
          console.log(err);
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("error");
          props.setSnackbarMsg("Bild Upload fehlgeschlagen");
        });
    } else {
      axios
        .post(props.API_PATH + "update/questions", questionData)
        .then(function () {
          props.setOpenSnackbar(true);
          props.setFetchAll(true);
          props.setSnackbarSeverity("success");
          props.setSnackbarMsg("Frage erfolgreich bearbeitet!");
          setFetchQuestion(true);
          handleCloseEdit();
        })
        .catch(function (err) {
          console.error(err);
          console.log(err);
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("error");
          props.setSnackbarMsg("Fehler");
        });
    }
  };

  //   questions.forEach((question) => {
  //     props.categories.forEach((category) => {
  //       if (question.category_id === category.id) {
  //         question.category_id = category.name;
  //       }
  //     });
  //   });

  const removeData = (ids) => {
    axios
      .delete(`${props.API_PATH}deleteMulti/questions/id/(${ids})`)
      .then((response) => {
        if (response.status === 200) {
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("success");
          props.setSnackbarMsg("Frage/n gelöscht");
          setFetchQuestion(true);
          handleCloseDelete();
        } else {
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("error");
          props.setSnackbarMsg("Fehler");
        }
      });
    // .catch(err => {
    //     console.error(err);
    //         props.setOpenSnackbar(true)
    //         props.setSnackbarSeverity('error')
    //         props.setSnackbarMsg('Fehler')

    // });
  };

  const config = {
    headers: { "content-type": "multipart/form-data" },
  };

  const pageSize = 20;
  const total = Math.ceil(foundQuestions.length / pageSize);
  const [page, setPage] = useState(1);

  let newQuestions = [];

  for (let index = 0; index < foundQuestions.length; index++) {
    const newQuestion = foundQuestions[index];
    for (let index = 0; index < props.categories.length; index++) {
      const category = props.categories[index];
      if (category.id === newQuestion.category_id) {
        newQuestion.category_name = category.name;
      }
    }
    newQuestions.push(newQuestion);
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={classes.paper} elevation={3}>
          <Typography variant="h5" gutterBottom>
            {foundQuestions.length} Fragen
          </Typography>
          <Box p={2}>
            <TextField
              multiline
              variant="outlined"
              value={q}
              onChange={(e) => setQ(e.target.value)}
              placeholder="Fragen durchsuchen"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search></Search>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Pagination
            count={total}
            onChange={(event, page) => setPage(page)}
            page={page}
            shape="rounded"
          />
          <List>
            {newQuestions
              .slice(pageSize * (page - 1), pageSize * page)
              .map((question) => (
                <ListItem key={question.id}>
                  <Card style={{ width: "100%" }}>
                    <CardActionArea onClick={() => handleOpenPreview(question)}>
                      <CardMedia
                        image={props.API_PATH + question.image}
                        title={question.qImage}
                      />
                      <CardContent>
                        <Grid container alignItems="center">
                          <Grid item xs={2}>
                            <Avatar
                              style={
                                question.approved
                                  ? {
                                      width: 40,
                                      height: 40,
                                      backgroundColor: green[400],
                                    }
                                  : {
                                      width: 40,
                                      height: 40,
                                      backgroundColor: red[400],
                                    }
                              }
                            >
                              <p style={{ fontSize: 15 }}>{question.id}</p>
                            </Avatar>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="subtitle2">
                              Kategorie
                            </Typography>
                            <Typography variant="h6">
                              {question.category_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="subtitle2">Author</Typography>
                            <Typography variant="h6">
                              {question.author}
                            </Typography>
                          </Grid>
                        </Grid>
                        <hr></hr>
                        <Grid
                          container
                          spacing={6}
                          justify="center"
                          alignItems="center"
                        >
                          <Grid item xs={4}>
                            <Typography
                              fontSize={12}
                              dangerouslySetInnerHTML={{
                                __html: props.makeBold(question.question),
                              }}
                            ></Typography>
                          </Grid>
                          <Grid item xs={2}>
                            {question.image && question.image !== "NULL" ? (
                              <img
                                style={{ maxHeight: 80, maxWidth: "100%" }}
                                src={props.API_PATH + question.image}
                              />
                            ) : (
                              <BlockIcon></BlockIcon>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <Grid
                              container
                              justify="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                alignItems="center"
                                justify="center"
                              >
                                <Typography
                                  style={{ fontSize: 10 }}
                                  fontSize={12}
                                >
                                  Richtige Antwort
                                </Typography>
                                <Typography
                                  color="green"
                                  style={{ color: "green" }}
                                  fontSize={12}
                                  dangerouslySetInnerHTML={{
                                    __html: props.makeBold(
                                      question.answer_good
                                    ),
                                  }}
                                ></Typography>
                                {/* <Chip
                                  variant="outlined"
                                  size="small"
                                  p={0.2}
                                  icon={<CheckIcon></CheckIcon>}
                                  label={
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: props.makeBold(
                                          question.answer_good
                                        ),
                                      }}
                                    ></p>
                                  }
                                  color="primary"
                                /> */}
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  style={{ fontSize: 10 }}
                                  fontSize={12}
                                >
                                  1.Falsche Antwort
                                </Typography>
                                <Typography
                                  style={{ color: "red" }}
                                  fontSize={12}
                                  dangerouslySetInnerHTML={{
                                    __html: props.makeBold(
                                      question.answer_bad_1
                                    ),
                                  }}
                                ></Typography>
                                {/*                                 
                                <Chip
                                  p={0.2}
                                  variant="outlined"
                                  size="small"
                                  icon={<NotInterestedIcon></NotInterestedIcon>}
                                  label={
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: props.makeBold(
                                          question.answer_bad_1
                                        ),
                                      }}
                                    ></p>
                                  }
                                  color="secondary"
                                /> */}
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  style={{ fontSize: 10 }}
                                  fontSize={12}
                                >
                                  2.Falsche Antwort
                                </Typography>
                                <Typography
                                  style={{ color: "red" }}
                                  fontSize={12}
                                  dangerouslySetInnerHTML={{
                                    __html: props.makeBold(
                                      question.answer_bad_2
                                    ),
                                  }}
                                ></Typography>
                                {/* <Chip
                                  variant="outlined"
                                  size="small"
                                  icon={<NotInterestedIcon></NotInterestedIcon>}
                                  label={
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: props.makeBold(
                                          question.answer_bad_2
                                        ),
                                      }}
                                    ></p>
                                  }
                                  color="secondary"
                                /> */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Box display="flex" flexDirection="row">
                          <Box p={2}>
                            <Typography variant="subtitle2">
                              Kategorie
                            </Typography>
                            <Typography variant="h6">
                              {question.category_name}
                            </Typography>
                          </Box>
                          <Box p={2}>
                            <Typography variant="subtitle2">Author</Typography>
                            <Typography variant="h6">
                              {question.author}
                            </Typography>
                          </Box>
                        </Box> */}
                        {/* <Box display="flex" flexDirection="row">
                          <Box p={0.2}>
                            <Typography
                              fontSize={12}
                              dangerouslySetInnerHTML={{
                                __html: props.makeBold(question.question),
                              }}
                            ></Typography>
                          </Box>
                          <Box p={2}>
                            <Typography variant="subtitle2">
                              Bild zur Frage
                            </Typography>
                            {question.image && question.image !== "NULL" ? (
                              <img
                                style={{ maxHeight: 80 }}
                                src={props.API_PATH + question.image}
                              />
                            ) : (
                              <BlockIcon></BlockIcon>
                            )}
                          </Box>
                          <Box display="flex" flexDirection="column" p={0.2}>
                            <Chip
                              variant="outlined"
                              size="small"
                              p={0.2}
                              icon={<CheckIcon></CheckIcon>}
                              label={
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: props.makeBold(
                                      question.answer_good
                                    ),
                                  }}
                                ></p>
                              }
                              color="primary"
                            />
                            <Chip
                              p={0.2}
                              variant="outlined"
                              size="small"
                              icon={<NotInterestedIcon></NotInterestedIcon>}
                              label={
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: props.makeBold(
                                      question.answer_bad_1
                                    ),
                                  }}
                                ></p>
                              }
                              color="secondary"
                            />
                            <Chip
                              variant="outlined"
                              size="small"
                              icon={<NotInterestedIcon></NotInterestedIcon>}
                              label={
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: props.makeBold(
                                      question.answer_bad_2
                                    ),
                                  }}
                                ></p>
                              }
                              color="secondary"
                            />
                          </Box>
                        </Box> */}
                      </CardContent>
                    </CardActionArea>
                    {isAdmin === 3 ? null : (
                      <CardActions>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleOpenEdit(question)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleOpenDelete(question)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </CardActions>
                    )}
                  </Card>
                </ListItem>
              ))}
          </List>

          <Pagination
            count={total}
            onChange={(event, page) => setPage(page)}
            page={page}
            shape="rounded"
          />
          <PreviewQuestion
            categories={props.categories}
            openPreview={openPreview}
            handleClosePreview={handleClosePreview}
            questionObj={questionObj}
            makeBold={props.makeBold}
            API_PATH={props.API_PATH}
          />
          <DeleteQuestion
            questionObj={questionObj}
            openDelete={openDelete}
            handleCloseDelete={handleCloseDelete}
            removeData={removeData}
            API_PATH={props.API_PATH}
          />
          <EditQuestion
            questionObj={questionObj}
            setQuestionObj={setQuestionObj}
            openEdit={openEdit}
            handleCloseEdit={handleCloseEdit}
            isValid={props.isValid}
            setIsValid={props.setIsValid}
            categories={props.categories}
            API_PATH={props.API_PATH}
            handleSubmit={handleSubmit}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
