import axios from "axios";
axios.defaults.withCredentials = true;

// const API_PATH = 'https://aq.ecknology.de:4000/'
//const API_PATH = 'http://localhost:4001/'
const API_PATH = process.env.REACT_APP_API_URL

// set token to the axios
export const setAuthToken = token => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    else {
        delete axios.defaults.headers.common['Authorization'];
    }
}

// verify refresh token to generate new access token if refresh token is present
export const verifyTokenService = async () => {
    try {
        return await axios.post(`${API_PATH}verifyToken`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

// user login API to validate the credential
export const userLoginService = async (email, password) => {
    try {
        return await axios.post(`${API_PATH}users/signin`, { email, password })
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

// manage user logout
export const userLogoutService = async () => {
    try {
        return await axios.post(`${API_PATH}users/logout`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}