import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import React, { useState } from "react";

import axios from "axios";
import { forwardRef } from "react";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import Setup from "./Setup";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  image: {
    position: "relative",
    height: 60,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 60,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    maxHeight: "100%",
    maxWidth: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  colorDot: {
    padding: "5px",
    marginLeft: "calc(50% - 15px)",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    color: "white",
  },
  colorPicker: {
    width: "80%",
    height: "40px",
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function TabPanel(props) {
  const classes = useStyles();

  const { input, data, setData, value, index, name, url, ...other } = props;

  const [columns, setColumns] = useState([
    { title: "ID", field: "id", editable: "never", width: "10" },
    { title: "Name", field: "name" },
    {
      render: (rowData) => (
        <p>
          {props.addZero(new Date(rowData.created_at).getDate()) +
            "." +
            props.addZero(new Date(rowData.created_at).getMonth() + 1) +
            "." +
            new Date(rowData.created_at).getFullYear() +
            " " +
            props.addZero(new Date(rowData.created_at).getHours()) +
            ":" +
            props.addZero(new Date(rowData.created_at).getMinutes()) +
            ":" +
            props.addZero(new Date(rowData.created_at).getSeconds())}
        </p>
      ),
      title: "Erstelldatum",
      field: "created_at",
      editable: "never",
    },
  ]);

  const removeData = (ids) => {
    axios
      .delete(`${props.API_PATH}deleteMulti/${url}/id/(${ids})`)
      .then(() => {
        props.setOpenSnackbar(true);
        props.setFetchAll(true);
        props.setSnackbarSeverity("success");
        props.setSnackbarMsg("gelöscht");
      })
      .catch((err) => {
        console.error(err);
        props.setOpenSnackbar(true);
        props.setSnackbarSeverity("error");
        props.setSnackbarMsg("Fehler");
      });
  };

  const onSave = (id, data) => {
    axios
      .post(props.API_PATH + url + "/id/" + id, data)
      .then(() => {
        props.setOpenSnackbar(true);
        props.setSnackbarSeverity("success");
        props.setSnackbarMsg("aktualisiert");
        props.setFetchAll(true);
      })
      .catch((err) => {
        console.error(err);
        props.setOpenSnackbar(true);
        props.setSnackbarSeverity("error");
        props.setSnackbarMsg("Fehler");
      });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let theData = new FormData();
    theData = input;
    axios
      .post(props.API_PATH + url, theData)
      .then(() => {
        setData({});
        props.setOpenSnackbar(true);
        props.setFetchAll(true);
        props.setSnackbarSeverity("success");
        props.setSnackbarMsg("erstellt");
      })
      .catch((err) => {
        console.error(err);
        let error = err.toString();
        if (error.search("413") !== -1) {
          props.setSnackbarMsg("Fehler, Icon Datei zu Groß!");
        } else {
          props.setSnackbarMsg("Fehler");
        }
        props.setOpenSnackbar(true);
        props.setSnackbarSeverity("error");
      });
  };
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h5" gutterBottom>
                {name} Erstellen
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Geben Sie den Namen ein und drücken Sie auf absenden.
              </Typography>
              <br></br>
              <form className={""} noValidate autoComplete="off">
                <Grid container justify="center" spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth className={""} variant="outlined">
                      <InputLabel htmlFor="modulname">{name}</InputLabel>
                      <OutlinedInput
                        id="modulname"
                        value={input.name}
                        onChange={(event) =>
                          setData({ ...input, name: event.target.value })
                        }
                        labelWidth={200}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      size="large"
                      variant="outlined"
                      onClick={handleSubmit}
                      className={""}
                      type="submit"
                    >
                      <Check></Check>Absenden
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h5" gutterBottom>
                {name}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Sie können die Tabelle sortieren, durchsuchen, filtern und
                bearbeiten.
              </Typography>
              <MaterialTable
                title=""
                columns={columns}
                data={data}
                icons={tableIcons}
                options={{
                  filtering: true,
                  selection: true,
                }}
                actions={[
                  {
                    tooltip: "Remove All Selected",
                    icon: tableIcons.Delete,
                    onClick: (evt, data) => {
                      let idArray = [];
                      data.forEach((value) => {
                        idArray.push(value.id);
                      });
                      removeData(idArray.toString());
                    },
                  },
                ]}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = { name: newData.name };
                        onSave(oldData.id, dataUpdate);
                        resolve();
                      }, 1000);
                    }),
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

// function a11yProps(index) {
//   return {
//     id: `scrollable-force-tab-${index}`,
//     "aria-controls": `scrollable-force-tabpanel-${index}`,
//   };
// }

export default function Software(props) {
  const [tab, setTab] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  // const [software, setSoftware] = React.useState({});
  // const [softwareVersion, setSoftwareVersion] = React.useState({});
  // const [modul, setModul] = React.useState({});
  const [category, setCategory] = React.useState({});

  return (
    <div>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label={<Typography variant="h6">Grundeinstellungen</Typography>} />
        <div
          style={{
            width: "22px",
            borderRight: "2px solid #3f51b5",
            marginRight: 20,
          }}
        ></div>
        {/* <Tab label={<Typography variant="h6">Modul</Typography>} />
        <Tab label={<Typography variant="h6">Software</Typography>} />
        <Tab label={<Typography variant="h6">Software Version</Typography>} /> */}
        <Tab label={<Typography variant="h6">Kategorien</Typography>} />
      </Tabs>
      <div
        role="tabpanel"
        hidden={tab !== 0}
        id={`scrollable-force-tabpanel-0`}
        aria-labelledby={`scrollable-force-tab-0`}
      >
        {tab === 0 && (
          <Setup
            emailTemplate={props.emailTemplate}
            categories={props.categories}
            software={props.software}
            softwareVersions={props.softwareVersions}
            moduls={props.moduls}
            customers={props.customers}
            setFetchAll={props.setFetchAll}
            setupData={props.setupData}
            isAdmin={props.isAdmin}
            addZero={props.addZero}
            handleClose={props.handleClose}
            setOpenSnackbar={props.setOpenSnackbar}
            setSnackbarMsg={props.setSnackbarMsg}
            setSnackbarSeverity={props.setSnackbarSeverity}
            API_PATH={props.API_PATH}
            questionsLength={props.questionsLength}
          />
        )}
      </div>
      <TabPanel value={tab} index={1} />
      {/* <TabPanel
        value={tab}
        addZero={props.addZero}
        name={"Modul"}
        url={"moduls"}
        data={props.moduls}
        setData={setModul}
        input={modul}
        index={2}
        setOpenSnackbar={props.setOpenSnackbar}
        setSnackbarMsg={props.setSnackbarMsg}
        setSnackbarSeverity={props.setSnackbarSeverity}
        API_PATH={props.API_PATH}
        setFetchAll={props.setFetchAll}
      />
      <TabPanel
        value={tab}
        addZero={props.addZero}
        name={"Software"}
        url={"software"}
        data={props.software}
        setData={setSoftware}
        input={software}
        index={3}
        setOpenSnackbar={props.setOpenSnackbar}
        setSnackbarMsg={props.setSnackbarMsg}
        setSnackbarSeverity={props.setSnackbarSeverity}
        API_PATH={props.API_PATH}
        setFetchAll={props.setFetchAll}
      />
      <TabPanel
        value={tab}
        addZero={props.addZero}
        name={"Software Version"}
        url={"software_versions"}
        data={props.softwareVersions}
        setData={setSoftwareVersion}
        input={softwareVersion}
        index={4}
        setOpenSnackbar={props.setOpenSnackbar}
        setSnackbarMsg={props.setSnackbarMsg}
        setSnackbarSeverity={props.setSnackbarSeverity}
        API_PATH={props.API_PATH}
        setFetchAll={props.setFetchAll}
      /> */}
      <TabPanel
        value={tab}
        addZero={props.addZero}
        name={"Kategorien"}
        url={"categories"}
        data={props.categories}
        setData={setCategory}
        input={category}
        index={2}
        setOpenSnackbar={props.setOpenSnackbar}
        setSnackbarMsg={props.setSnackbarMsg}
        setSnackbarSeverity={props.setSnackbarSeverity}
        API_PATH={props.API_PATH}
        setFetchAll={props.setFetchAll}
      />
    </div>
  );
}
