import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ImageIcon from "@material-ui/icons/Image";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import { DropzoneAreaBase } from "material-ui-dropzone";
import React, { useEffect } from "react";
import { IconButton } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";

const theme = createMuiTheme({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 100,
      },
    },
    MuiDropzonePreviewList: {
      root: {
        height: 50,
      },
      imageContainer: {
        top: -80,
        minHeight: 100,
      },
      image: {
        padding: 8,
        height: "100%",
      },
      removeButton: {
        top: "50%",
      },
    },
  },
});

export default function QuestionForm(props) {
  console.log(props.questionObj);
  return (
    <Container>
      <Paper elevation={3} style={{ padding: 40 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Voreinstellungen</Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  label="Active"
                  name="approved"
                  value={props.questionObj.active}
                  checked={props.questionObj.approved === 1 ? true : false}
                  onChange={(event) =>
                    props.setQuestionObj({
                      ...props.questionObj,
                      approved: event.target.checked ? 1 : 0,
                    })
                  }
                ></Checkbox>
              }
              label="Active"
              labelPlacement="right"
            />
          </Grid>

          <Grid item xs={6}>
            <Select
              fullWidth
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              //   open={open}
              //   onClose={handleClose}
              //   onOpen={handleOpen}
              variant="outlined"
              value={props.questionObj.category_id}
              onChange={(event) => {
                console.log(event.target.value);
                props.setQuestionObj({
                  ...props.questionObj,
                  category_id: event.target.value,
                });
              }}
            >
              {props.categories.map((category) => {
                return <MenuItem value={category.id}>{category.name}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              id="author"
              name="author"
              label="Autor"
              variant="outlined"
              error={!props.questionObj.author > 0 ? true : false}
              multiline={true}
              fullWidth
              value={props.questionObj.author}
              onChange={(event, newValue) => {
                props.setQuestionObj({
                  ...props.questionObj,
                  author: event.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Frage und Antworten</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="left">
              Sie können Buchstaben, Wörter und ganze Textpassagen Fett, Kursiv
              Unterstrichen und Durchgestrichen darstellen.<br></br>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="left">
              Und so geht's:
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" align="left">
              <b>FETT</b>
              <br></br>
              <i>KURSIV</i>
              <br></br>
              <u>UNTERSTRICHEN</u>
              <br></br>
              <s>DURCHGESTRICHEN</s>
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1" align="left">
              {"{b ...Fetter Text... b}"} = <b>...Fetter Text...</b>
              <br></br>
              {"{i ...Kursiver Text... i}"} = <i>...Kursiver TEXT...</i>
              <br></br>
              {"{u ...Unterstrichen... u}"} = <u>...Unterstrichen...</u>
              <br></br>
              {"{s ...Durchgestrichen... s}"} = <s>...Durchgestrichen...</s>
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              required
              rows={3}
              id="question"
              name="question"
              label="Frage"
              variant="outlined"
              error={!props.questionObj.question > 0 ? true : false}
              multiline={true}
              fullWidth
              value={props.questionObj.question}
              onChange={(event, newValue) => {
                props.setQuestionObj({
                  ...props.questionObj,
                  question: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            {props.questionObj.image === "NULL" ||
            props.questionObj.image === "" ? null : (
              <img
                style={{ width: "100%" }}
                src={
                  props.questionObj.imageFile
                    ? props.questionObj.imageFile[0].data
                    : props.API_PATH + props.questionObj.image
                }
              />
            )}
          </Grid>
          <Grid item xs={1}>
            {props.questionObj.image === "NULL" ||
            props.questionObj.image === "" ? null : (
              <IconButton
                aria-label="delete"
                onClick={() =>
                  props.setQuestionObj({ ...props.questionObj, image: "" })
                }
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={2}>
            <MuiThemeProvider theme={theme}>
              <DropzoneAreaBase
                name="image"
                Icon={ImageIcon}
                acceptedFiles={["image/*"]}
                filesLimit={1}
                dropzoneText={""}
                fileObjects={
                  new File(
                    [props.API_PATH + props.questionObj.image],
                    props.questionObj.image,
                    {
                      type: "image/png",
                    }
                  )
                }
                onAdd={(files) => {
                  props.setQuestionObj({
                    ...props.questionObj,
                    imageFile: files,
                    image: files[0].file.name,
                  });
                }}
                onDelete={() =>
                  props.setQuestionObj({
                    ...props.questionObj,
                    image: "",
                    imageFile: null,
                  })
                }
              />
            </MuiThemeProvider>
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              id="answer_1"
              name="answer_good"
              variant="outlined"
              label="Richtige Antwort"
              error={!props.questionObj.answer_good > 0 ? true : false}
              multiline={true}
              rows={4}
              fullWidth
              value={props.questionObj.answer_good}
              onChange={(event, newValue) => {
                props.setQuestionObj({
                  ...props.questionObj,
                  answer_good: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              id="answer_2"
              name="answer_bad_1"
              variant="outlined"
              label="Falsche Antwort 1"
              error={!props.questionObj.answer_bad_1 > 0 ? true : false}
              multiline={true}
              rows={4}
              fullWidth
              value={props.questionObj.answer_bad_1}
              onChange={(event, newValue) => {
                props.setQuestionObj({
                  ...props.questionObj,
                  answer_bad_1: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              id="answer_3"
              name="answer_bad_2"
              variant="outlined"
              label="Falsche Antwort 2"
              error={!props.questionObj.answer_bad_2 > 0 ? true : false}
              multiline={true}
              rows={4}
              fullWidth
              value={props.questionObj.answer_bad_2}
              onChange={(event, newValue) => {
                props.setQuestionObj({
                  ...props.questionObj,
                  answer_bad_2: event.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
