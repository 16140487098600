import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import React from "react";
import Preview from "./Preview";
import QuestionForm from "./QuestionForm";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Checkout(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const [questionObj, setQuestionObj] = React.useState({});
  const [isValid, setIsValid] = React.useState(false);

  const submitButton = React.useRef();

  const handleNext = (event) => {
    if (
      questionObj.category_id &&
      questionObj.author &&
      questionObj.question &&
      questionObj.answer_good &&
      questionObj.answer_bad_1 &&
      questionObj.answer_bad_2
    ) {
      setActiveStep(activeStep + 1);
      setIsValid(true);
    } else {
      setIsValid(false);
      props.setOpenSnackbar(true);
      props.setSnackbarSeverity("error");
      props.setSnackbarMsg("Überprüfen Sie Ihre Eingaben");
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const steps = ["Frage Erstellen", "Vorschau", "Absenden"];

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <QuestionForm
            API_PATH={props.API_PATH}
            questionObj={questionObj}
            setQuestionObj={setQuestionObj}
            software={props.software}
            softwareVersions={props.softwareVersions}
            moduls={props.moduls}
            customers={props.customers}
            answers={props.answers}
            categories={props.categories}
            isValid={isValid}
            setIsValid={setIsValid}
          />
        );
      case 1:
        return (
          <Preview
            questionObj={questionObj}
            // imagePath={questionObj.image[0].data}
            makeBold={props.makeBold}
          />
        );
      case 2:
        return (
          <div>
            <Container>
              <Grid container justify="center" alignItems="center" spacing={5}>
                <Grid item md={10} xs={12} align="left">
                  <Typography variant="h5" align="left">
                    Frage Speichern!
                  </Typography>
                </Grid>
                <Grid item md={10} xs={12} align="center ">
                  <Typography variant="h5" align="center">
                    Soll die Frage jetzt aktiviert werden?
                  </Typography>
                </Grid>
                <Grid item md={10} xs={12} align="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedI"
                        checked={questionObj.approved}
                        onChange={() =>
                          setQuestionObj({
                            ...questionObj,
                            approved: !questionObj.approved,
                          })
                        }
                      />
                    }
                    label="Frage aktivieren"
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const handleSubmit = (event, obj) => {
    event.preventDefault();
    handleNext();
    let file = null;
    if (obj.imageFile) {
      file = obj.imageFile[0].file;
      delete obj.imageFile;
    }
    let questionObject = obj;
    questionObject.software_id = 0;
    questionObject.software_version_id = 0;
    questionObject.modul_id = 0;
    // questionObject.software_id = questionObject.software_id
    //   ? questionObject.software_id.id
    //   : 0;
    // questionObject.software_version_id = questionObject.software_version_id
    //   ? questionObject.software_version_id.id
    //   : 0;
    // questionObject.modul_id = questionObject.modul_id
    //   ? questionObject.modul_id.id
    //   : 0;
    // questionObject.category_id = questionObject.category_id
    //   ? questionObject.category_id.id
    //   : 0;
    questionObject.approved
      ? (questionObject.approved = 1)
      : (questionObject.approved = 0);
    questionObject.image = "";

    let questionData = new FormData();

    questionData = questionObject;

    // const config = {
    //     headers: { 'content-type': 'multipart/form-data' }
    // }

    let imageData = new FormData();

    if (file) {
      imageData.append("file", file);
      axios
        .post(props.API_PATH + "upload", imageData)
        .then(function (response) {
          questionData.image = response.data.name;
          axios
            .post(props.API_PATH + "create/questions", questionData)
            .then(function () {
              props.setOpenSnackbar(true);
              props.setFetchAll(true);
              props.setSnackbarSeverity("success");
              props.setSnackbarMsg("Frage erfolgreich erstellt!");
            })
            .catch(function (err) {
              console.error(err);
              console.log("error");
              console.log(err);
              props.setOpenSnackbar(true);
              props.setSnackbarSeverity("error");
              props.setSnackbarMsg("Fehler");
            });
        })
        .catch(function (err) {
          console.error(err);
          console.log("error image");
          console.log(err);
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("error");
          props.setSnackbarMsg("Bild Upload fehlgeschlagen");
        });
    } else {
      axios
        .post(props.API_PATH + "create/questions", questionData)
        .then(function () {
          props.setOpenSnackbar(true);
          props.setFetchAll(true);
          props.setSnackbarSeverity("success");
          props.setSnackbarMsg("Frage erfolgreich erstellt!");
        })
        .catch(function (err) {
          console.error(err);
          console.log(err);
          props.setOpenSnackbar(true);
          props.setSnackbarSeverity("error");
          props.setSnackbarMsg("Fehler");
        });
    }

    // if (file) {
    //     const config = {
    //         headers: { 'content-type': 'multipart/form-data' }
    //     }
    //     console.log(file.name);
    //     let imageData = new FormData()
    //     const extension = file.name.slice(file.name.lastIndexOf("."));
    //     const name = insertedId + extension
    //     //file = { ...file, name: name }
    //     file.name = name
    //     console.log(file);
    //     imageData.append('file', file)
    //     axios
    //         .post(props.API_PATH + 'upload', imageData, config)
    //         .then(() => console.log('image uploaded'))
    //         .catch(err => {
    //             console.error(err);
    //             props.setOpenSnackbar(true)
    //             props.setSnackbarSeverity('error')
    //             props.setSnackbarMsg('Fehler beim Bildupload')
    //         });
    // }
  };

  const newQuestion = () => {
    // Force rerender component
    setActiveStep(0);
    setQuestionObj({});
  };

  return (
    <React.Fragment>
      {/* <CssBaseline /> */}
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={3}>
            <Typography component="h1" variant="h5">
              Frage erstellen
            </Typography>
            <br></br>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Frage erfolgreich erstellt.
                  </Typography>
                  <Typography variant="subtitle1">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={newQuestion}
                      className={classes.button}
                    >
                      {"Eine weitere Frage erstellen"}
                    </Button>
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Zurück
                      </Button>
                    )}
                    {activeStep === 2 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(event) => handleSubmit(event, questionObj)}
                        className={classes.button}
                        ref={submitButton}
                        type="submit"
                      >
                        {activeStep === steps.length - 1
                          ? "Frage Absenden"
                          : "Weiter"}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={handleNext}
                        className={classes.button}
                        ref={submitButton}
                      >
                        {activeStep === steps.length - 1
                          ? "Frage Absenden"
                          : "Weiter"}
                      </Button>
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
